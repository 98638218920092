import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { Grid, PageSettingsModel, Sort } from '@syncfusion/ej2-angular-grids'
import { ApiService } from '../services/api.service';
import { GenerateExcelService } from '../services/generate-excel.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { execFileSync } from 'child_process';
import { __values } from 'tslib';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { table } from 'console';


/**
* @title Dialog Overview
**/
Grid.Inject(Sort);

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    public PackageStatus: any[] = ['DiscardLink', 'Rivise Package', 'Diliver Package', 'Close Wip']
    public isLoaded: boolean = false;
    public allowUser: boolean = false;
    public vendorName: any;
    public vendorId: any = "";
    public vendorRole: any = "";
    public fromDate: any = "";
    public toDate: any = "";
    public journalAbrv: any = "";
    public filterVendorId: any = "";
    public filterVendorName: any = "";
    public filterVendorRole: any = "";
    // public supportImg: any = 'false';

    public data!: any[];
    public vendors: string[] = []
    public journals: string[] = []
    public status: string[] = []
    public packageCount: any = []
    public package1 = 0;
    public package2 = 0;
    public package3 = 0;
    public package4 = 0;
    public package5 = 0;
    public package6 = 0;
    public package7 = 0;
    public package8 = 0;
    public package9 = 0;
    public package10 = 0;
    public package11 = 0;
    public package12 = 0;
    public progress!: number;
    public disabledEdi: any;
    public uiVendorStatus: any;
    public dataLength: any;
    public excelInteration: any = 0;
    public pageCount: any = 0;
    public dataCount: any = 0;
    public excelData: any = [[], [], []]
    public excel: any = "wait";
    public excelReportData: any = "false";
    public excelAuthorQueries: any = "false";
    public excelJournalReport: any = "false";

    public sorting = false;
    public sortingOrder = 0
    public sortingCol = "Id"

    public primaryXAxis!: Object;
    public primaryYAxis!: Object;
    public chartData!: Object[];
    public chartData2!: Object[];
    public marker!: Object;
    public palette!: string[];
    public legendSettings!: Object;
    public Difference_In_Days = 0;
    public remaining_days = 0;
    public selectAllText!:string; 
    public excelFilterData: string[] = [
        'ID',
        'Vendor',
        'Article Id',
        'Journal Code',

        'Editorial Reference Code',
        'Input File',
        'Zip File',
        'Booking-In Type',
        'Request Id',

        'Job Id',
        'Status',
        'Created On Date',
        'Created On Time',
        'Modified On Date',
        'Modified On Time',
        'Age',
        'Workflow Events & System Performance Indicator',
        'Manuscript Metadata Information',
        'Number(s) of Mismatch Between Metadata & Manuscript',
        'Author Query Count',
    ];
    public placeholder: string = 'Select excel field';
    public mode!: string;
    public allPackages: any = {}

    @ViewChild('checkbox', { static: true })
    public mulObj!: MultiSelectComponent;

    @Output() getDisabledEditStatus = new EventEmitter<boolean>();

    // 
    @ViewChild('ddlelement')
    public dropDownListObject!: DropDownListComponent;

    public uiStatus = {
        "Link Sent": 'Book-in Link Sent',
        // "Submitted": 'Submitted',
        "NotSubmitted": 'Book-in In Process',
        "Delivered": 'Book-in Completed',
        "NotDelivered": 'Final Package In-Process to Deliver',
        "JSONInvalid": 'Invalid Input Type/Metadata Missing',
        "Initiated": 'Manuscript Received',
        "LinkNotSent": 'Booking Link Not Sent',
        "JSONReceived": 'JSON Received by eAssistant',
        "ManuscriptUploadedToCUC": 'Manuscript Uploaded To CUC',
        "CUCFailed": 'CUC Failed',
        "JSONAwaited": 'JSON Awaited',
        // "LinkFailure": 'Link Failure'
    }
    public pageSettings!: PageSettingsModel
    constructor(public apiService: ApiService, public generateExcel: GenerateExcelService,
        public dialog: MatDialog, private _router: Router, private toastr: ToastrService) { }

    public pageNo = 1;

    public search = "";
    public userId = 0;

    public allUsers!: any[];

    public tatAverage: any = {
        AvgJsonReceived: 0,
        AvgLinkSent: 0,
        AvgLinkSubmitted: 0,
        AvgDelivered: 0
    };

    public pageNoEle: any;

    public clientName: any;

    public endTime = function (dt: any, minutes: any) {
        // let currentTime = new Date()
        let logoutTime = new Date(dt.getTime() + minutes * 60000)
        //  return new Date(dt.getTime() + minutes*60000);
        // // console.log(currentTime)
        // // console.log(logoutTime)
        return (logoutTime)
    }

    public activeTime = (new Date()).toString();
    public logoutTime = this.endTime(new Date(), 15);

    public apiStatusData: any;

    public selectedCols: any = [];

    ngOnInit(): void {
        this.selectAllText='Select All'
        this.mode = 'CheckBox'
        localStorage.removeItem("excelPercentage")
        // this.logoutTime = this.endTime(new Date(),0.5)
        // console.log('oninit logout', this.logoutTime);

        // setInterval(this.autoLogout,1000)

        this.allowUser = this.apiService.getLoginStatus()
        if (this.allowUser != true) {
            // // // // console.log('Got Data');
        }

        this.vendorName = sessionStorage.getItem('VendorName')
        this.vendorId = sessionStorage.getItem('Id')
        this.vendorRole = sessionStorage.getItem('Role')

        // 
        this.filterVendorId = this.vendorId == "24" ? 0 : this.vendorId;
        this.filterVendorName = this.vendorName;
        this.filterVendorRole = this.vendorRole;


        this.clientName = this.vendorRole;
        localStorage.setItem('clientName', this.clientName)
        // this.vendorName = "aptara"
        // this.vendorId = 26;
        // this.vendorRole = "Vendor";

        if (this.vendorRole == 'Admin') {
            this.vendorId = 0;
        }
        this.userId = this.vendorId
        this.getVendors();
        this.getJournals(this.userId);
        this.getStatus();
        // 
        this.getPackageCount(this.vendorId, null, null, null);
        this.getTatAverage();
        this.getConfigResponse();


        // this.sortOptions = { columns: [{ field: 'OrderID', direction: 'Ascending' }, { field: 'ShipCity', direction: 'Descending' }]
    }
    onCreate(args: any): void{   
        this.mulObj.selectAll(true);   
      }   
    // public downloadOutputPackage(jobId: any) {
    //     this.apiService.downloadOutputPackage(jobId).subscribe(res => {
    //         
    //         console.log(jobId);
    //         console.log("Package downloaded for JobId: " + jobId);
    //     },
    //         error => {
    //             console.log(error);
    //         }
    //     )
    // }

    public autoLogout(logoutTime: any) {
        this.activeTime = (new Date()).toString()
        // // console.log('activeTime', this.activeTime);
        // // console.log('logoutTime', logoutTime)
        if (this.activeTime == logoutTime) {
            // console.log('logging out...................')
            // this.logout();
            sessionStorage.setItem('isLoggedIn', "false");
            sessionStorage.setItem('VendorName', "");
            sessionStorage.setItem('Id', "");
            sessionStorage.setItem('Role', "");
            // window.location.href = 'http://uateassistantreport.icodex.in/log-in';
            // this._router.navigate(['/log-in']);
            window.location.reload();
        }
    }



    ngAfterViewInit(): void {

        // this.logoutTime = this.endTime(new Date(),0.5) 

        // let index = 0;

        // let cols = document.getElementsByClassName('e-headercell')

        // let colNames = ['id','vendor','ArticleId','JournalCode','EditorialReferenceCode',
        //                 'InputFile','ZipFile','RequestId','JobId','Status','CreatedOn',
        //                 'ModifiedOn','Age'];

        // // console.log("************columns", cols);

        // for(let i in cols){
        //     cols[i].id = colNames[index];
        //     index = index + 1;
        //     if(index >= 13){
        //         break;
        //     }
        // }

        this.pageNoEle = document.getElementById('pageNo') as HTMLInputElement;

        let submit = document.getElementById("submit")
        submit?.addEventListener('click', () => {
            this.pageNo = parseInt(this.pageNoEle.value);
            this.applyFilters(this.search, this.sortingOrder, this.sortingCol)

        })
        this.vendorName = sessionStorage.getItem('VendorName')
        this.vendorId = sessionStorage.getItem('Id')
        this.vendorRole = sessionStorage.getItem('Role')

        this.filterVendorId = this.vendorId == "24" ? 0 : this.vendorId;
        this.filterVendorName = this.vendorName;
        this.filterVendorRole = this.vendorRole;


        // this.vendorName = "aptara"
        // this.vendorId = 26;
        // this.vendorRole = "Vendor";
        // 
        // this.apiService.getLoginStatus()
        // // this.allowUser = this.apiService.getLoginStatus()
        // if(this.allowUser != true ){
        //     // document.location.href = 'http://localhost:4200/log-in';
        // }

        this.pageNoEle.value = this.pageNo;
        this.asyncCall(this.pageNo, this.search);
        this.pageSettings = { pageSize: 10 };

        let myInterval = setInterval(this.autoLogout, 1000, this.logoutTime.toString())

        let mainBody = document.getElementById('mainBody') as HTMLInputElement;
        mainBody.addEventListener('mouseover', () => {
            clearInterval(myInterval)
            let vendorValue = document.getElementById('vendors_hidden') as HTMLInputElement;
            let vendorNameValue = vendorValue.value

            if (vendorNameValue == "Content Service Team") {
                // clientId =32;
                vendorNameValue = "EASSISTANT@WILEY.COM";
            }
            for (let i in this.allUsers) {
                // // // // // console.log(this.allUsers[i]);
                if (this.allUsers[i].Vendor == vendorNameValue) {
                    this.getJournals(this.allUsers[i].Id)
                }
            }
            if (vendorNameValue == 'All') {
                this.getJournals(0);
            }
            // if(this.vendorId== '32'){
            //     this.getJournals(0);
            // }

            this.logoutTime = this.endTime(new Date(), 15)
            myInterval = setInterval(this.autoLogout, 1000, this.logoutTime.toString())
        })



        // this.apiService.getCreadentials('TOPSUser1','1tng0C%7').subscribe(
        //   apiData => {
        //     // // // // console.log(apiData);
        //   }
        // )

        let searchField = document.getElementById('search') as HTMLInputElement;

        searchField.addEventListener('mousedown', () => {
            // // // // // console.log("search field clicked")

            searchField.style.width = '25rem';
        })

        let contentBody = document.getElementById('contentBody') as HTMLInputElement;

        contentBody.addEventListener('click', () => {
            // // // // // console.log("search field clicked")

            searchField.style.width = '13rem';
        })

    }


    public async asyncCall(pageNum: any, search: any) {
        // // // // console.log('calling');
        const result = await this.storeData(pageNum, search);
        // // // // console.log(result);
        // expected output: "resolved"
    }

    public storeData(pageNum: any, search: any) {
        return new Promise(resolve => {
            if (this.vendorRole == 'Admin') {
                this.vendorId = 0;
            }
            return this.getData(pageNum, search, this.vendorId)
        });
    }

    public onPrev() {
        this.isLoaded = false;
        this.pageNo = this.pageNo - 1;
        this.pageNoEle.value = this.pageNo;

        if (this.pageNo <= 0) {
            this.pageNo = 1
            this.pageNoEle.value = this.pageNo;
        }
        // // // // console.log('prev ' + this.pageNo);
        // this.asyncCall(this.pageNo, this.search);
        this.applyFilters(this.search, this.sortingOrder, this.sortingCol)
    }

    public onNext() {
        this.isLoaded = false;

        if (this.data.length == 0) {

            this.pageNo = this.pageNo;
            this.pageNoEle.value = this.pageNo;
        }
        else {
            this.pageNo = this.pageNo + 1;
            this.pageNoEle.value = this.pageNo;
        }
        // // // // // console.log(this.data.length);
        // // // // console.log('next ' + this.pageNo);
        // this.asyncCall(this.pageNo, this.search);
        // 
        this.applyFilters(this.search, this.sortingOrder, this.sortingCol)
    }

    public onFirstPage() {
        this.isLoaded = false;
        this.pageNo = 1;
        this.pageNoEle.value = this.pageNo;
        this.applyFilters(this.search, this.sortingOrder, this.sortingCol)
    }

    public onLastPage() {
        this.isLoaded = false;
        this.pageNo = this.pageCount
        this.pageNoEle.value = this.pageNo;
        this.applyFilters(this.search, this.sortingOrder, this.sortingCol)
    }

    public getData(pageNum: any, search: any, clientId: any) {
        var tableData: any = [];
        let pageSize = document.getElementById("pageSize") as HTMLInputElement
        this.apiService.getData(pageNum, search, clientId, 0, 'Id', pageSize.value).subscribe(
            apiData => {
                // console.log("UAT Data",apiData)

                let index = 0;

                let cols = document.getElementsByClassName('e-headercell')

                let colNames = ['Id', 'FirstName', 'ArticleId', 'JournalAbbrevation', 'EditorialReferenceCode',
                    'InputFileName', 'ZipFileName', 'TPReferenceNumber', 'JobId', 'Status', 'CreatedOn',
                    'ModifiedOn', 'Age'];

                // console.log("************columns", cols);

                for (let i in cols) {
                    cols[i].id = colNames[index];

                    cols[i].addEventListener('click', () => {

                        // 
                        this.sorting = !this.sorting
                        // let order;
                        if (this.sorting == false) {
                            this.sortingOrder = 0
                        }
                        else {

                            this.sortingOrder = 1
                        }

                        this.sortingCol = cols[i].id;

                        // console.log(cols[i].id);

                        this.applyFilters(this.search, this.sortingOrder, this.sortingCol)

                    })

                    index = index + 1;
                    if (index >= 13) {
                        break;
                    }
                }
                // console.log("***************************************APIData", apiData);
                // this.pageCount = apiData.Pagination.totalPages;
                // this.dataCount = apiData.Pagination.totalCount;

                this.dataCount = this.package1
                this.pageCount = Math.ceil(this.package1 / parseInt(pageSize.value))
                // this.pageSize = document.getElementById("pageSize") as HTMLInputElement
                // this.pageCount = Math.ceil((this.package1) / parseInt(pageSize.value));
                sessionStorage.setItem('fromData', "");
                sessionStorage.setItem('toDate', "");


                for (let i in apiData.Report) {
                    // // // // console.log("***************************************Link",apiData.Report[i].Link)
                    if (!(apiData.Report[i].Link)) {

                    }
                    if (apiData.Report[i].Status != "Disabled&PendingForBilling") {
                        tableData.push(
                            {
                                id: apiData.Report[i].Id,
                                vendor: apiData.Report[i].FirstName,
                                ArticleId: apiData.Report[i].ArticleId,
                                JournalCode: apiData.Report[i].JournalAbbrevation,
                                EditorialReferenceCode: apiData.Report[i].EditorialReferenceCode,
                                InputFile: apiData.Report[i].InputFileName,
                                ZipFile: apiData.Report[i].ZipFileName,
                                PackageType: apiData.Report[i].IsManual ? 'Manual' : 'Regular',
                                RequestId: apiData.Report[i].TPReferenceNumber,
                                JobId: apiData.Report[i].JobId,
                                Status: apiData.Report[i].Status,
                                CreatedOn: apiData.Report[i].CreatedOn,
                                ModifiedOn: apiData.Report[i].ModifiedOn,
                                Age: apiData.Report[i].Status.toLowerCase() != "jsoninvalid" ? apiData.Report[i].Age : "-",
                                ManuScriptError: apiData.Report[i].ManuScriptError,
                                Link: apiData.Report[i].Link,
                                Details: 'Details'
                            }
                        )
                    }
                }
                // 
                // // // // // console.log(this.data)
                // // // // // console.log(tableData)
                this.data = tableData;
                this.dataLength = this.data.length;
                this.isLoaded = true;
                // // console.log("----------------------------------Number1--------------------------------------------")
                // // console.log(this.isLoaded)
                // // console.log("table data get data******************************************",this.data)

            }, error => console.error(error)
        )
        // 
        // // // // // console.log(tableData)
        return tableData;
    }


    public getVendors() {
        if (this.vendorRole == 'Admin') {
            var vendorData: any = ["All"];
        }
        else {
            var vendorData: any = []
        }
        this.apiService.getVendors().subscribe(
            apiData => {

                this.allUsers = apiData;


                // // // // // console.log(apiData);

                // 
                for (let i in apiData) {

                    if (apiData[i].Vendor != "TOPSUSER1" &&
                        apiData[i].Vendor != "TOPSUser1" && apiData[i].Vendor != "SUPPORT" && apiData[i].Vendor != "EASSISTANT@WILEY.COM") {
                        vendorData.push(apiData[i].Vendor)
                    }
                    if (apiData[i].Vendor === "EASSISTANT@WILEY.COM") {
                        vendorData.push("Content Service Team")
                    }
                }

                // // // // // console.log(vendorData);

                // // 
                // // // // // // console.log(this.data)
                // // // // // // console.log(tableData)
                this.vendors = vendorData;
                // // // // // // console.log(this.data)

                // 
                if (this.vendorRole == "Vendor") {
                    this.vendors = [this.vendorName]
                }
                else if (this.vendorRole == "ContentServiceTeam") {
                    this.vendors = ["ContentServiceTeam"]
                }

            }
        )
        // 
        // // // // // console.log(tableData)
        // return tableData;
    }

    public getJournals(userId: any) {
        var journalData: any = ["All"];
        this.apiService.getJournals(userId).subscribe(
            apiData => {

                // // console.log("journal data**********************",apiData);

                // 
                for (let i in apiData) {
                    journalData.push(apiData[i].JournalAbrv)
                }
                // // // // // console.log(journalData);

                // // 
                // // // // // // console.log(this.data)
                // // // // // // console.log(tableData)
                this.journals = journalData;
                // // // // // console.log(this.journals)

            }
        )
        // 
        // // // // // console.log(tableData)
        // return tableData;
    }


    public getStatus() {
        var statusData: any = ["All"];
        this.apiService.getStatus().subscribe(
            apiData => {
                this.apiStatusData = apiData
                // // // // console.log("status api", apiData);
                // // // // // console.log(this.uiStatus['LinkSent']);

                // 
                // for (let i of apiData) {
                //     let key = apiData[i].Name
                //         statusData.push(this.uiStatus)
                // }
                // for(let i in this.uiStatus){
                //     // // // // console.log(i)
                // }


                if (this.vendorRole == "Vendor") {
                    this.uiVendorStatus = {
                        "ManuscriptUploadedToCUC": 'Manuscript Uploaded',
                        "Link Sent": 'Book-in Link Sent',
                        "NotSubmitted": 'Book-in In Process',
                        "Delivered": 'Book-in Completed',
                        "NotDelivered": 'Final Package In-Process to Deliver',
                        "JSONInvalid": 'Invalid Input Type/Metadata Missing',
                        // "LinkFailure": 'Link Failure'
                    }


                    for (var [key1, val1] of Object.entries(this.uiVendorStatus)) {
                        // 
                        if (this.uiVendorStatus.hasOwnProperty(key1)) {
                            // 
                            for (let i in apiData) {
                                if (apiData[i].Name == key1) {
                                    statusData.push(val1)
                                }
                            }
                            // // // // // console.log(key1 + " -> " + val1);
                        }
                    }
                }
                else {
                    for (var [key2, val2] of Object.entries(this.uiStatus)) {
                        if (this.uiStatus.hasOwnProperty(key2)) {
                            // 
                            for (let i in apiData) {
                                if (apiData[i].Name == key2) {
                                    statusData.push(val2)
                                }
                            }
                            // // // // // console.log(key2 + " -> " + val2);
                        }
                    }

                }
                // // // // console.log("status actual", statusData);

                // // 
                // // // // // // console.log(this.data)
                // // // // // // console.log(tableData)
                this.status = statusData;
                // // // // console.log("status data: ",this.status)

            }
        )
        // 
        // // // // // console.log(tableData)
        // return tableData;
    }

    public getPackageCount(clienId: any, fromDate: any, toDate: any, journalAbrv: any) {
        this.apiService.getPackage1Count(clienId, 0, fromDate, toDate, journalAbrv).subscribe(
            apiData => {
                // 
                // console.log(apiData);
                this.package1 = apiData;
                this.allPackages['Initiated'] = apiData;
            },
            error => {
                if (error.status == '404') {
                    this.package1 = 0;
                }
            }
        )

        this.apiService.getPackage2Count(clienId, 1, fromDate, toDate, journalAbrv).subscribe(
            apiData => {
                // // // // // console.log(apiData);
                this.package2 = apiData;
                this.allPackages['ManuscriptUploadedToCUC'] = apiData;
            },
            error => {
                if (error.status == '404') {
                    this.package2 = 0;
                }
            }
        )

        this.apiService.getPackage3Count(clienId, 2, fromDate, toDate, journalAbrv).subscribe(
            apiData => {
                // // // // // console.log(apiData);
                this.package3 = apiData;
                this.allPackages['JSONReceived'] = apiData;
            },
            error => {
                if (error.status == '404') {
                    this.package3 = 0;
                }
            }
        )

        this.apiService.getPackage4Count(clienId, 3, fromDate, toDate, journalAbrv).subscribe(
            apiData => {
                // // // // // console.log(apiData);
                this.package4 = apiData;
                this.allPackages['Link Sent'] = apiData;
            },
            error => {
                if (error.status == '404') {
                    this.package4 = 0;
                }
            }
        )

        this.apiService.getPackage5Count(clienId, 4, fromDate, toDate, journalAbrv).subscribe(
            apiData => {
                // // // // // console.log(apiData);
                this.package5 = apiData;
                this.allPackages['NotSubmitted'] = apiData;
            },
            error => {
                if (error.status == '404') {
                    this.package5 = 0;
                }
            }
        )

        this.apiService.getPackage6Count(clienId, 5, fromDate, toDate, journalAbrv).subscribe(
            apiData => {
                // // // // // console.log(apiData);
                this.package6 = apiData;
                this.allPackages['Delivered'] = apiData;
            },
            error => {
                if (error.status == '404') {
                    this.package6 = 0;
                }
            }
        )

        this.apiService.getPackage7Count(clienId, 6, fromDate, toDate, journalAbrv).subscribe(
            apiData => {
                console.log(apiData);
                this.package7 = apiData;
                this.allPackages['NotDelivered'] = apiData;
            },
            error => {
                if (error.status == '404') {
                    this.package7 = 0;
                }
            }
        )

        this.apiService.getPackage8Count(clienId, 7, fromDate, toDate, journalAbrv).subscribe(
            apiData => {
                // // // // // console.log(apiData);
                this.package8 = apiData;
                this.allPackages['LinkNotSent'] = apiData;
            },
            error => {
                if (error.status == '404') {
                    this.package8 = 0;
                }
            }
        )

        this.apiService.getPackage9Count(clienId, 8, fromDate, toDate, journalAbrv).subscribe(
            apiData => {
                // // // // // console.log(apiData);
                this.package9 = apiData;
                this.allPackages['CUCFailed'] = apiData;
            },
            error => {
                if (error.status == '404') {
                    this.package9 = 0;
                }
            }
        )

        this.apiService.getPackage10Count(clienId, 9, fromDate, toDate, journalAbrv).subscribe(
            apiData => {
                // // // // // console.log(apiData);
                this.package10 = apiData;
                this.allPackages['JSONInvalid'] = apiData;
            },
            error => {
                if (error.status == '404') {
                    this.package10 = 0;
                }
            }
        )

        this.apiService.getPackage11Count(clienId, 10, fromDate, toDate, journalAbrv).subscribe(
            apiData => {
                // // // // // console.log(apiData);
                this.package11 = apiData;
                this.allPackages['JSONAwaited'] = apiData;
            },
            error => {
                if (error.status == '404') {
                    this.package11 = 0;
                }
            }
        )

        this.apiService.getPackage12Count(clienId, 11, fromDate, toDate, journalAbrv).subscribe(
            apiData => {
                // // // // // console.log(apiData);
                this.package12 = apiData;
                this.allPackages['LinkFailure'] = apiData;
            },
            error => {
                if (error.status == '404') {
                    this.package12 = 0;
                }
            }
        )
        // 

        // // // // // console.log(tableData)
        // return tableData;
    }


    public searchData() {

        let query = document.getElementById("search") as HTMLInputElement;

        // // // // // console.log(query.value);
        // // // // // console.log("before: ",this.search);
        this.search = query.value;

        if (this.search.length < 1 || this.search.length > 40) {
            this.toastr.warning("Character length should be between 1 to 40");
        }
        else {
            this.asyncCall(this.pageNo, this.search);
        }
        // // // // // console.log("after: ",this.search);

    }

    public resetData() {
        this.search = "";
        this.pageNo = 1;
        this.pageNoEle.value = this.pageNo;

        this.filterVendorId = sessionStorage.getItem('Id') == "24" ? 0 : sessionStorage.getItem('Id')
        this.filterVendorName = sessionStorage.getItem('VendorName')
        this.filterVendorRole = sessionStorage.getItem('Role')

        // 

        // this.asyncCall(this.pageNo, this.search);

        let ele = document.getElementById('search') as HTMLInputElement;
        ele.value = this.search;

        let vendorValue: any = document.getElementById('vendors_hidden') as HTMLInputElement
        let journalValue: any = document.getElementById('journals_hidden') as HTMLInputElement
        let statusValue: any = document.getElementById('status_hidden') as HTMLInputElement
        let startDateValue: any = document.getElementById('startDate_input') as HTMLInputElement
        let endDateValue: any = document.getElementById('endDate_input') as HTMLInputElement

        // this.dropDownListObject.value = null;
        vendorValue.value = null;
        journalValue.value = null;
        statusValue.value = null;
        startDateValue.value = null;
        endDateValue.value = null;

        this.vendors = [];
        this.journals = [];
        this.status = [];

        localStorage.setItem('clientName', this.vendorRole)

        let pageSize = document.getElementById("pageSize") as HTMLInputElement
        pageSize.value = "10";

        this.getVendors();
        this.getJournals(this.userId);
        this.getStatus();
        this.getPackageCount(this.vendorId, null, null, null);
        this.applyFilters(this.search, 0, 'Id');
        // this.pageCount = Math.ceil((this.package1) / parseInt(pageSize.value));

        // document.location.href = 'http://localhost:4200/dashboard';

    }

    public getConfigResponse() {
        let pageSize = document.getElementById("pageSize") as HTMLInputElement
        this.apiService.getConfigResponse(this.pageNo, pageSize.value).subscribe(
            apiData => {

                // // // // console.log('api responce', apiData);
                // // // // // console.log(apiData.headers.get('Paging-Headers'))
            }
        )
    }
    // event: HttpProgressEvent = { type: 0, loaded: 0, total: 0 };

    public downloadExcel() {
        localStorage.removeItem("excelPercentage")
        // // // // // console.log('generating excel');
        this.excel = "downloading";

        let vendorValue = document.getElementById('vendors_hidden') as HTMLInputElement
        let journalValue = document.getElementById('journals_hidden') as HTMLInputElement
        let statusValue = document.getElementById('status_hidden') as HTMLInputElement
        let startDateValue = document.getElementById('startDate_input') as HTMLInputElement
        let endDateValue = document.getElementById('endDate_input') as HTMLInputElement
        let clientId;
        let statusType = "";
        let journalType = "";

        clientId = this.vendorId

        let vendorNameValue = vendorValue.value


        if (vendorNameValue == "All") {
            clientId = 0;
        }

        if (vendorNameValue == "Content Service Team") {
            // clientId =32;
            vendorNameValue = "EASSISTANT@WILEY.COM";
        }



        for (let i in this.allUsers) {
            if (this.allUsers[i].Vendor == vendorNameValue) {
                clientId = this.allUsers[i].Id
            }
        }

        for (var [key, val] of Object.entries(this.uiStatus)) {
            if (statusValue.value == val) {
                statusType = key
            }
        }

        if (statusValue.value == "All") {
            statusType = ""
        }

        if (journalValue.value == "All") {
            journalType = ""
        }
        else {
            journalType = journalValue.value;
        }

        let obj = {
            "clientId": clientId,
            "journalAbrv": journalType,
            "statusType": statusType,
            "fromDate": startDateValue.value,
            "toDate": endDateValue.value,
            "search": "",
            "isExcel": true,
        }
        sessionStorage.setItem('fromData', obj.fromDate);
        sessionStorage.setItem('toDate', obj.toDate);
        sessionStorage.setItem('fromData', obj.fromDate);
        sessionStorage.setItem('toDate', obj.toDate);

        // this.apiService.getExcelData(obj).subscribe(
        //     apiData => {
        //         debugger
        //         let data = apiData.Report
        //         this.excel = "wait";
        //         // console.log('excel Data',apiData);
        //         this.generateExcel.generateExcel(data);
        //         // 
        //     }
        // )
        // let Difference_In_Days = 0;
        // let remaining_days = 0;
        if (obj.fromDate == "") {
            obj.fromDate = "2021-01-05"
        }

        if (obj.toDate == "") {
            let d = new Date()
            obj.toDate = d.getFullYear() + "-" + String(d.getMonth()).padStart(2, '0') + "-" + String(d.getDate()).padStart(2, '0')

            // if (obj.fromDate != "" && obj.toDate) {

            let splitDate1 = (obj.fromDate).split("-")
            let splitDate2 = (obj.toDate).split("-")

            let combineDate1 = splitDate1[1] + "/" + splitDate1[2] + "/" + splitDate1[0]
            let combineDate2 = splitDate2[1] + "/" + splitDate2[2] + "/" + splitDate2[0]

            let date1 = new Date(combineDate1);
            let date2 = new Date(combineDate2);
            let Difference_In_Time = date2.getTime() - date1.getTime()
            this.Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            // console.log(this.Difference_In_Days)
            this.remaining_days = this.Difference_In_Days % 30;
            // console.log(this.remaining_days)
            this.Difference_In_Days = this.Difference_In_Days - this.remaining_days;
            this.excelInteration = this.Difference_In_Days / 30;
            // console.log(this.excelInteration)

            // }
            // if (Difference_In_Days <= 31) {
            // let beginDate = "2021-01-05"
            // let today: any = new Date();
            // let dd = String(today.getDate()).padStart(2, '0');
            // let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            // let yyyy = today.getFullYear();
            // today = yyyy + '-' + mm + '-' + dd;
            // if (obj.fromDate != "") {
            //     beginDate = obj.fromDate
            // }
            // if (obj.toDate != "") {
            //     today = obj.toDate
            // }
        }
        //  debugger
        // console.log(obj);
        if (this.Difference_In_Days == 0) {
            this.excelApi(obj, this.Difference_In_Days, this.remaining_days, 50)

        }
        else {
            obj.fromDate = obj.fromDate;
            obj.toDate = this.addDays(new Date(obj.fromDate), 30)
            this.excelApi(obj, this.Difference_In_Days, this.remaining_days, 100 / this.excelInteration)
            this.Difference_In_Days = this.Difference_In_Days - 30
            this.excelInteration = this.excelInteration - 1;
        }
    }
    public addDays(date: any, days: any) {
        const copy = new Date(Number(date))
        copy.setDate(date.getDate() + days)
        // return copy
        return copy.getFullYear() + "-" + String(copy.getMonth() + 1).padStart(2, '0') + "-" + String(copy.getDate()).padStart(2, '0')
    }


    public excelApi(obj: any, Difference_In_Days: any, remaining_days: any, per: any) {
        // console.log(obj)
        this.apiService.getExcelData(obj).subscribe(
            apiData => {
                // console.log(apiData);
                this.excelReportData = 'true';
                this.excelAuthorQueries = 'false';
                this.excelJournalReport = "false";
                this.apiService.getAuthorquery(obj.fromDate, obj.toDate).subscribe(
                    authorQuery => {
                        // console.log(authorQuery)
                        this.excelReportData = 'false';
                        this.excelAuthorQueries = 'true';
                        this.excelJournalReport = "false";
                        this.apiService.getJornalsReport(obj.clientId).subscribe(
                            (event: any) => {

                                this.excelReportData = 'false';
                                this.excelAuthorQueries = 'false';
                                this.excelJournalReport = "true";
                                let data: any
                                // let total=event['total']
                                // // console.log(event,event.total);
                                // switch (event.type) {

                                if (event.type == HttpEventType.DownloadProgress) {
                                    // const kbLoaded = Math.round(event.loaded / 1024);
                                    this.progress = Math.round(event.loaded / event.total * 100);
                                    // console.log(`Uploaded! ${this.progress}%`);
                                    // // console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
                                }
                                else if (event.type == HttpEventType.Response) {
                                    // debugger
                                    // this.progress = 100;
                                    // console.log('😺 Done!', event.body);
                                    this.excelReportData = 'false';
                                    this.excelAuthorQueries = 'false';
                                    this.excelJournalReport = "false";
                                    data = [apiData.Report, event.body, authorQuery]
                                    this.excelData[0] = this.excelData[0].concat(data[0])
                                    this.excelData[2] = this.excelData[2].concat(data[2])

                                    console.log(per + "%");
                                    // this.excelData = [apiData.Report, event.body, authorQuery]



                                    // debugger
                                    // console.log(obj.fromDate, "####", obj.toDate, " : ", data);
                                    // data = obj.fromDate.concat(obj.toDate);

                                    if (Difference_In_Days == 0 && remaining_days == 0) {
                                        this.apiService.setexcelStatus(true, 100)
                                        this.excelData[1] = this.excelData[1].concat(data[1])
                                        // console.log(this.excelData);
                                        this.generateExcel.generateExcel(this.excelData, this.selectedCols);
                                        this.excel = "wait";
                                        this.excelData = [[], [], []]
                                        // window.close ();
                                    }
                                    else if (Difference_In_Days == 0 && remaining_days > 0) {
                                        // debugger
                                        this.apiService.setexcelStatus(false, per)
                                        obj.fromDate = this.addDays(new Date(obj.toDate), 1);
                                        obj.toDate = this.addDays(new Date(obj.toDate), remaining_days)
                                        this.Difference_In_Days = Difference_In_Days
                                        this.remaining_days = 0
                                        if (this.excelInteration == 0) {
                                            this.excelInteration = 1
                                        }
                                        this.excelApi(obj, this.Difference_In_Days, this.remaining_days, 100 / this.excelInteration)
                                        this.apiService.getexcelStatus
                                        this.excelInteration = this.excelInteration - 1
                                        
                                    }
                                    else {
                                        // debugger
                                        this.apiService.setexcelStatus(false, per)
                                        obj.fromDate = this.addDays(new Date(obj.toDate), 1);
                                        obj.toDate = this.addDays(new Date(obj.toDate), 30)
                                        if (this.excelInteration == 0) {
                                            this.excelInteration = 1
                                        }
                                        this.excelApi(obj, this.Difference_In_Days, this.remaining_days, 100 / this.excelInteration)
                                        this.Difference_In_Days = Difference_In_Days - 30
                                        this.remaining_days = remaining_days
                                        this.excelInteration = this.excelInteration - 1
                                    }


                                    // let data: any;
                                    // data = [apiData.Report, jorurnalReport, authorQuery]
                                    // this.excel = "wait";
                                    // this.generateExcel.generateExcel(data);
                                    // // console.log('excel Data', data);
                                }
                            }
                        )
                    }
                )
            }
        )
    }


    public excelApiAll() {
        this.excel = 'downloading';

        this.selectedCols = this.mulObj.value;

        let vendorValue = document.getElementById('vendors_hidden') as HTMLInputElement
        let journalValue = document.getElementById('journals_hidden') as HTMLInputElement
        let statusValue = document.getElementById('status_hidden') as HTMLInputElement
        let startDateValue = document.getElementById('startDate_input') as HTMLInputElement
        let endDateValue = document.getElementById('endDate_input') as HTMLInputElement

        let clientName: any = vendorValue.value != "All" && vendorValue.value != "" ? vendorValue.value : ""
        if (sessionStorage.getItem('Role') != "Admin") {
            clientName = sessionStorage.getItem('VendorName')
        }
        let journalAbr = journalValue.value != "All" && journalValue.value != "" ? journalValue.value : ""
        let startDate = startDateValue.value != "" ? startDateValue.value : "2021-01-05"

        let d = new Date()
        let eDate = d.getFullYear() + "-" + String(d.getMonth() + 1).padStart(2, '0') + "-" + String(d.getDate()).padStart(2, '0')
        let endDate = endDateValue.value != "" ? endDateValue.value : eDate

        endDate = this.addDays(new Date(endDate), 1);


        let clientId = 0;
        let statusId = 0;

        debugger
        for (let i in this.allUsers) {
            if (this.allUsers[i].Vendor.toLowerCase() == clientName.toLowerCase()) {
                clientId = this.allUsers[i].Id
            }
        }

        if (this.vendorRole == "Vendor") {
            for (var [key1, val1] of Object.entries(this.uiVendorStatus)) {
                if (val1 == statusValue.value) {
                    for (let j in this.apiStatusData) {
                        if (this.apiStatusData[j].Name == key1) {
                            statusId = this.apiStatusData[j].Id
                        }
                    }
                }
            }
        }
        else {
            for (var [key2, val2] of Object.entries(this.uiStatus)) {
                if (val2 == statusValue.value) {
                    for (let j in this.apiStatusData) {
                        if (this.apiStatusData[j].Name == key2) {
                            statusId = this.apiStatusData[j].Id
                        }
                    }
                }
            }
        }

        this.apiService.getFullExcelData(clientId, statusId, journalAbr, startDate, endDate).subscribe((event: any) => {
            // console.log("fdata",event)
            this.excel = 'downloading';

            let vendorValue = document.getElementById('vendors_hidden') as HTMLInputElement
            let journalValue = document.getElementById('journals_hidden') as HTMLInputElement
            let statusValue = document.getElementById('status_hidden') as HTMLInputElement
            let startDateValue = document.getElementById('startDate_input') as HTMLInputElement
            let endDateValue = document.getElementById('endDate_input') as HTMLInputElement

            let data;

            if (event.type == HttpEventType.DownloadProgress) {
                // debugger
                this.progress = Math.round(event.loaded / event.total * 100);
                console.log(`Uploaded! ${this.progress}%`);
                this.excel = 'downloading';
            }
            else if (event.type == HttpEventType.Response) {
                this.apiService.setexcelStatus(false, 98)
                this.excel = 'wait';
                data = event.body
                console.log("combinedData///////", data)
                let combinedData = []

                combinedData[0] = []
                let clientName = vendorValue.value != "All" && vendorValue.value != "" ? vendorValue.value : ""
                let journalAbr = journalValue.value != "All" && journalValue.value != "" ? journalValue.value : ""
                let status = statusValue.value != "All" && statusValue.value != "" ? statusValue.value : ""
                let startDate = startDateValue.value
                let endDate = endDateValue.value

                let reportData = []
                let clientData = []
                let clientJournalReport = []

                let rowData = data.Table
                for (let i in rowData) {
                    for (let j in data.Table2) {
                        if (rowData[i].Id == data.Table2[j].JobMasterId) {
                            for (let [k, v] of Object.entries(data.Table2[j])) {
                                if (v == null) {
                                    rowData[i][k] = 0
                                }
                                else {
                                    rowData[i][k] = v
                                }
                            }
                        }
                    }
                }

                for (let i in rowData) {
                    for (let [k, v] of Object.entries(rowData[i])) {
                        if (v == null || v == "") {
                            rowData[i][k] = "-"
                        }
                        else {
                            rowData[i][k] = v
                        }
                    }
                }
                for (let i in rowData) {
                    if (clientName != "") {
                        if (rowData[i].FtpUserName.toLowerCase() == clientName.toLowerCase()) {
                            clientData.push(rowData[i]);
                            clientJournalReport.push({
                                "Client": rowData[i].FtpUserName,
                                "JournalName": rowData[i].JournalAbrv,
                                "CreatedOn": rowData[i].CreatedOn
                            })
                        }
                    }
                    else {
                        clientData.push(rowData[i])
                        clientJournalReport.push({
                            "Client": rowData[i].FtpUserName,
                            "JournalName": rowData[i].JournalAbrv,
                            "CreatedOn": rowData[i].CreatedOn
                        })
                    }
                }

                let JournalData = []
                let JournalReportData = []
                for (let i in clientData) {
                    if (Object.keys(this.uiStatus).includes(clientData[i].Name1)) {
                        if (journalAbr != "") {
                            if (clientData[i].JournalAbrv.toLowerCase() == journalAbr.toLowerCase()) {
                                JournalData.push(clientData[i])
                                JournalReportData.push({
                                    "Client": clientData[i].FtpUserName,
                                    "JournalName": clientData[i].JournalAbrv,
                                    "CreatedOn": clientData[i].CreatedOn
                                })
                            }
                        }
                        else {
                            JournalData.push(clientData[i])
                            JournalReportData.push({
                                "Client": clientData[i].FtpUserName,
                                "JournalName": clientData[i].JournalAbrv,
                                "CreatedOn": clientData[i].CreatedOn
                            })
                        }
                    }
                }

                // let dateRangeData = []
                // let dateRangeJournalReportData = []
                // for (let i in JournalData) {
                //     if (startDate == "") {
                //         startDate = "2021-01-04"
                //     }
                //     if (endDate == "") {
                //         let d = new Date()
                //         endDate = d.getFullYear() + "-" + String(d.getMonth() + 1).padStart(2, '0') + "-" + String(d.getDate()).padStart(2, '0')
                //     }
                //     let fromDate = new Date(startDate)
                //     let toDate = new Date(endDate)
                //     let currentDate = new Date((JournalData[i].CreatedOn).split("T")[0])
                //     if ((fromDate.getTime() <= currentDate.getTime()) && (currentDate.getTime() <= toDate.getTime())) {
                //         dateRangeData.push(JournalData[i])
                //         dateRangeJournalReportData.push({
                //             "Client": JournalData[i].FtpUserName,
                //             "JournalName": JournalData[i].JournalAbrv,
                //             "CreatedOn": JournalData[i].CreatedOn
                //         })
                //     }
                // }

                reportData[1] = JournalReportData
                reportData[2] = data.Table1
                reportData[0] = JournalData

                this.apiService.setexcelStatus(true, 100)
                this.generateExcel.generateExcel(reportData, this.selectedCols);
            }

        })
    }


    public applyFilters(search: any, orderDirection: any, orderCol: any) {
        debugger
        this.isLoaded = false;
        this.excelData = [[], [], []]
        // // // // // console.log('applying filters');
        let vendorValue = document.getElementById('vendors_hidden') as HTMLInputElement
        let journalValue = document.getElementById('journals_hidden') as HTMLInputElement
        let statusValue = document.getElementById('status_hidden') as HTMLInputElement
        let startDateValue = document.getElementById('startDate_input') as HTMLInputElement
        let endDateValue = document.getElementById('endDate_input') as HTMLInputElement
        let clientId;
        let clientName;
        let statusType = "";
        let journalType = "";
        let vendorType = "";

        clientId = this.vendorId

        let vendorNameValue = vendorValue.value

        if (vendorNameValue == "All") {
            clientId = 0;
        }
        if (vendorNameValue == "Content Service Team") {
            //  clientId =32;
            vendorNameValue = "EASSISTANT@WILEY.COM";
        }
        // 
        for (let i in this.allUsers) {
            // // // // // console.log(this.allUsers[i]);
            if (this.allUsers[i].Vendor == vendorNameValue) {
                clientId = this.allUsers[i].Id == "24" ? "0" : this.allUsers[i].Id;
                clientName = vendorNameValue
                this.filterVendorId = this.allUsers[i].Id == "24" ? "0" : this.allUsers[i].Id;
                this.filterVendorName = vendorNameValue;
                this.filterVendorRole = this.allUsers[i].Roll;
            }
        }

        if (this.filterVendorId == "0") {
            this.filterVendorName = "Admin";
            this.filterVendorRole = "Admin";

        }
        // if (this.filterVendorId == "32") {
        //     this.filterVendorName = "EASSISTANT@WILEY.COM";
        //     this.filterVendorRole = "Vendor";

        // }

        for (var [key, val] of Object.entries(this.uiStatus)) {
            if (statusValue.value == val) {
                statusType = key
            }
        }

        if (statusValue.value == "All") {
            statusType = ""
        }

        if (journalValue.value == "All") {
            journalType = ""
            localStorage.setItem('journalAbrv', "")
        }
        else {
            journalType = journalValue.value;
            localStorage.setItem('journalAbrv', journalType)
        }


        // if (this.vendorRole == "Admin") {
        //     clientId = 0;
        // }

        // // // console.log(
        //     "vendor", vendorValue.value,
        //     "journal", journalValue.value,
        //     "status", statusType,
        //     "startDate", startDateValue.value,
        //     "endDate", endDateValue.value
        // )

        this.clientName = clientName;
        localStorage.setItem('clientName', this.clientName)

        let obj = {
            "clientId": clientId,
            "clientName": clientName,
            "journalAbrv": journalType,
            "statusType": statusType,
            "fromDate": startDateValue.value,
            "toDate": endDateValue.value,
            "search": search,
            "isExcel": false,
            "OrderDirection": orderDirection,
            "OrderByColumn": orderCol,
        }

        localStorage.setItem('fromData', obj.fromDate);
        localStorage.setItem('toDate', obj.toDate);
        localStorage.setItem('Id', obj.clientId);

        // console.log("****************client id", obj.clientId);

        // // // console.log(obj.fromDate,obj.toDate);

        // 
        let fromdate = null
        let todate = null
        if (obj.fromDate == "") {
            fromdate = null;
        }
        else {
            fromdate = obj.fromDate;
        }
        if (obj.toDate == "") {
            todate = null;
        }
        else {
            todate = obj.toDate;
        }

        let journalAbr
        if (obj.journalAbrv == "") {
            journalAbr = null
        }
        else {
            journalAbr = obj.journalAbrv
        }

        this.fromDate = fromdate;
        this.toDate = todate;
        this.journalAbrv = journalAbr;

        this.getPackageCount(obj.clientId, fromdate, todate, journalAbr);

        // Math.ceil(x)
        debugger
        // console.log(obj);

        let tableData: any = [];
        // 



        // let index = 0;

        // let cols = document.getElementsByClassName('e-headercell')

        // let colNames = ['Id', 'FirstName', 'ArticleId', 'JournalAbbrevation', 'EditorialReferenceCode',
        //     'InputFileName', 'ZipFileName', 'TPReferenceNumber', 'JobId', 'Status', 'CreatedOn',
        //     'ModifiedOn', 'Age'];

        // // console.log("************columns", cols);


        // for (let i in cols) {
        //     cols[i].id = colNames[index];

        //     cols[i].addEventListener('click', () => {

        //         this.sorting = !this.sorting
        //         let order;
        //         if(this.sorting == false){
        //             order = 0
        //         }
        //         else{
        //             order = 1
        //         }

        //         // console.log(cols[i].id);

        //         
        //         this.apiService.getFilterData(this.pageNo, obj, order, cols[i].id).subscribe(
        //             sortData => {
        //                 var tableSortData: any = [];
        //                 // console.log("Api Data: ", sortData);
        //                 this.pageCount = sortData.Pagination.totalPages;
        //                 this.dataCount = sortData.Pagination.totalCount;

        //                 sessionStorage.setItem('fromData', "");
        //                 sessionStorage.setItem('toDate', "");


        //                 for (let i in sortData.Report) {
        //                     // // // // console.log("***************************************Link",sortData.Report[i].Link)
        //                     if (!(sortData.Report[i].Link)) {

        //                     }
        //                     tableSortData.push(
        //                         {
        //                             id: sortData.Report[i].Id,
        //                             vendor: sortData.Report[i].FirstName,
        //                             ArticleId: sortData.Report[i].ArticleId,
        //                             JournalCode: sortData.Report[i].JournalAbbrevation,
        //                             EditorialReferenceCode: sortData.Report[i].EditorialReferenceCode,
        //                             InputFile: sortData.Report[i].InputFileName,
        //                             ZipFile: sortData.Report[i].ZipFileName,
        //                             RequestId: sortData.Report[i].TPReferenceNumber,

        //                             JobId: sortData.Report[i].JobId,
        //                             Status: sortData.Report[i].Status,
        //                             CreatedOn: sortData.Report[i].CreatedOn,
        //                             ModifiedOn: sortData.Report[i].ModifiedOn,
        //                             Age: sortData.Report[i].Age,
        //                             Link: sortData.Report[i].Link,
        //                             Details: 'Details'
        //                         }
        //                     )
        //                 }
        //                 // 
        //                 // // // // // console.log(this.data)
        //                 // console.log(tableSortData)
        //                 this.data = tableSortData;
        //                 this.dataLength = this.data.length;

        //             })
        //         })

        //     index = index + 1;
        //     if (index >= 13) {
        //         break;
        //     }
        // }




        // let pageNum = document.getElementById("pageNo") as HTMLInputElement

        // // console.log(pageNum.value);
        let pageSize = document.getElementById("pageSize") as HTMLInputElement
        // this.pageCount=Math.ceil((this.package1)/parseInt(pageSize.value));
        debugger
        // console.log(obj);
        // this.pageNo = parseInt(this.pageNoEle.value)
        this.apiService.getFilterData(this.pageNo, obj, pageSize.value).subscribe(
            apiData => {
                // // // console.log("Api data filter data******************************************",apiData);
                // this.pageCount = apiData.Pagination.totalPages;
                // this.pageCount = Math.ceil((this.package1) / parseInt(pageSize.value));
                // this.dataCount = apiData.Pagination.totalCount;

                if (obj['statusType'] != '' && obj['statusType'] != 'All') {
                    debugger
                    this.dataCount = this.allPackages[obj.statusType]
                    this.pageCount = Math.ceil(this.allPackages[obj.statusType] / parseInt(pageSize.value))
                } else {
                    this.dataCount = this.package1
                    this.pageCount = Math.ceil(this.package1 / parseInt(pageSize.value))
                }



                // 

                // console.log(obj);
                for (let i in apiData.Report) {
                    if (apiData.Report[i].Status != "Disabled&PendingForBilling") {

                        tableData.push(
                            {
                                id: apiData.Report[i].Id,
                                vendor: apiData.Report[i].FirstName,
                                ArticleId: apiData.Report[i].ArticleId,
                                JournalCode: apiData.Report[i].JournalAbbrevation,
                                EditorialReferenceCode: apiData.Report[i].EditorialReferenceCode,
                                InputFile: apiData.Report[i].InputFileName,
                                ZipFile: apiData.Report[i].ZipFileName,
                                PackageType: apiData.Report[i].IsManual ? 'Manual' : 'Regular',
                                RequestId: apiData.Report[i].TPReferenceNumber,
                                JobId: apiData.Report[i].JobId,
                                Status: apiData.Report[i].Status,
                                CreatedOn: apiData.Report[i].CreatedOn,
                                ModifiedOn: apiData.Report[i].ModifiedOn,
                                Age: apiData.Report[i].Status.toLowerCase() != "jsoninvalid" ? apiData.Report[i].Age : "-",
                                ManuScriptError: apiData.Report[i].ManuScriptError,
                                Link: apiData.Report[i].Link,
                                Details: 'Details'
                            }
                        )
                    }
                }
                this.data = tableData;
                this.dataLength = this.data.length;
                this.isLoaded = true;
                // // console.log("----------------------------------------Number2---------------------------")
                // // console.log(this.isLoaded);

                // // console.log("table data filter data******************************************",this.data,this.data.length)
            }
        )
    }

    public logout() {
        sessionStorage.setItem('isLoggedIn', "false");
        sessionStorage.setItem('VendorName', "");
        sessionStorage.setItem('Id', "");
        sessionStorage.setItem('Role', "");
        // window.location.href = 'http://uateassistantreport.icodex.in/log-in';
        this._router.navigate(['/log-in']);
    }

    public getTatAverage() {
        this.apiService.getTatAverage().subscribe(
            apiData => {
                this.tatAverage = apiData
                // console.log(this.tatAverage);
            }
        )
    }


    public openDialog(jobMasterId: any): void {

        let jobId: any = "";
        let details: any;
        for (let i in this.data) {
            if (this.data[i].id == jobMasterId) {
                jobId = this.data[i].JobId
            }
        }


        this.apiService.getDetails(jobMasterId).subscribe(
            apiData => {
                console.log('details', apiData);
                details = apiData;


                this.apiService.getStatusHistory(jobId).subscribe(
                    history => {

                        console.log('history response', history);


                        // // // // // console.log(apiData.headers.get('Paging-Headers'))

                        // let ele =  document.getElementById('BookinIdealTime')

                        // if(history.BookinIdealTimeEnd == null){
                        //     ele!.classList.add("hideField")   
                        // }
                        // else{
                        //     ele!.classList.add("showField")   
                        // }
                        const dialogRef = this.dialog.open(FinalRemark, {

                            width: '2000px',
                            height: '700px',
                            data: {
                                details: details,
                                jobid: jobId,
                                history: history,
                                loader: true
                            },
                        });


                        dialogRef.afterClosed().subscribe(result => {
                            // // // // console.log('The dialog was closed');

                            this.disabledEdi = result;

                            this.getDisabledEditStatus.emit(this.disabledEdi);
                        });
                    }
                )
            }


        )

        // let details = document.getElementById('41008') as HTMLInputElement



        // details.addEventListener("keyup", () => {
        //     // let okButton = document.getElementById('okButton')

        //     // okButton.classList.remove('disabled');

        //     // if (details.value == '') {
        //     //     // okButton.classList.add('disabled');
        //     // }
        //     // if (details.value.match(/^\s+$/) !== null) {
        //     //     // okButton.classList.add('disabled');
        //     // }
        // })

    }



    openScoreCard(): void {
        debugger
        let details: any;

        let fromDate = sessionStorage.getItem('fromData');
        let toDate = sessionStorage.getItem('toDate');
        let vendorId = sessionStorage.getItem('Id')
        let journalAbrv = sessionStorage.getItem('journalAbrv')

        this.apiService.getTotalData(vendorId, journalAbrv, fromDate, toDate).subscribe(
            apiData => {
                debugger
                console.log('detailsScoreCard', apiData);
                details = apiData;

                this.chartData = [
                    { type: 'Article Title', value: apiData.ArticleTitleRecall },
                    { type: 'Author Group', value: apiData.AuthorGroupRecall },
                    { type: 'Affiliation', value: apiData.AffiliationRecall },
                    { type: 'Corresponding Author', value: apiData.Corresponding_AuthorRecall },
                    { type: 'Corresponding Author Email', value: apiData.Corresponding_Author_EmailRecall },
                    { type: 'Abstract', value: apiData.AbstractRecall },
                    { type: 'Keyword', value: apiData.KeywordRecall },
                    { type: 'Figure', value: apiData.FigureRecall },
                    { type: 'Table', value: apiData.TableRecall },
                    { type: 'Reference', value: apiData.ReferenceRecall },
                ];

                this.chartData2 = [
                    { type: 'Article Title', value: apiData.ArticleTitlePrecision },
                    { type: 'Author Group', value: apiData.AuthorGroupPrecision },
                    { type: 'Affiliation', value: apiData.AffiliationPrecision },
                    { type: 'Corresponding Author', value: apiData.Corresponding_AuthorPrecision },
                    { type: 'Corresponding Author Email', value: apiData.Corresponding_Author_EmailPrecision },
                    { type: 'Abstract', value: apiData.AbstractPrecision },
                    { type: 'Keyword', value: apiData.KeywordPrecision },
                    { type: 'Figure', value: apiData.FigurePrecision },
                    { type: 'Table', value: apiData.TablePrecision },
                    { type: 'Reference', value: apiData.ReferencePrecision },
                ];

                this.primaryXAxis = {
                    valueType: 'Category',
                    title: 'Article Elements'
                };

                this.primaryYAxis = {
                    minimum: 0, maximum: 0.25,
                    interval: 0.05,
                    labelFormat: '{value}%',
                    title: 'Average in %',
                    // titleStyle: {
                    //   size: '16px', color: 'grey',
                    //   fontFamily: 'Segoe UI', fontWeight: 'bold'
                    // }
                }

                this.marker = { dataLabel: { visible: true } }

                this.palette = ["#0000FF", "#FFFF00"];

                this.legendSettings = {
                    visible: true,
                    position: 'Top',
                    // position: 'Custom',
                    location: { x: 200, y: 40 }
                };

                const dialogRef = this.dialog.open(ScoreCard, {
                    width: '2000px',
                    height: '700px',
                    data: {
                        chartData: this.chartData,
                        chartData2: this.chartData2,
                        primaryXAxis: this.primaryXAxis,
                        primaryYAxis: this.primaryYAxis,
                        marker: this.marker,
                        palette: this.palette,
                        legendSettings: this.legendSettings
                    },
                });


                dialogRef.afterClosed().subscribe(result => {
                    // // // // console.log('The dialog was closed');

                    this.disabledEdi = result;

                    this.getDisabledEditStatus.emit(this.disabledEdi);
                });
            }



        )
    }

}

interface HttpProgressEvent {
    type: HttpEventType.DownloadProgress | HttpEventType.UploadProgress
    loaded: number
    total: number
}

@Component({
    selector: 'recordInfo',
    templateUrl: 'details.html',
})

export class FinalRemark {

    public disabledEdit: boolean = false;
    public total: any;
    public TATPackageReceived: any;
    public TATJsonReceived: any;
    public TATLinkSent: any;
    public TATLinkSubmitted: any;
    public TATPackageDelivered: any;
    public TATLinkFirstSave: any;
    public TotalTime: any;
    public loader: boolean = false;

    @Output() getDisabledEditStatus = new EventEmitter<boolean>();

    constructor(
        private dialogRef: MatDialogRef<FinalRemark>,
        @Inject(MAT_DIALOG_DATA) public data: any, public apiService: ApiService) {

        this.loader = data.loader;

        // console.log(this.data)

        this.TATLinkFirstSave = this.getTimeFormat(this.data.history[0].TATLinkFirstSave != "" ? parseInt(this.data.history[0].TATLinkFirstSave) : 0);
        this.TATPackageReceived = this.getTimeFormat(this.data.history[0].TATPackageReceived != "" ? parseInt(this.data.history[0].TATPackageReceived) : 0);

        let t1 = (this.data.history[0].TATJsonReceived != "" ? parseInt(this.data.history[0].TATJsonReceived) : 0) + (this.data.history[0].TATCUCCall != "" ? parseInt(this.data.history[0].TATCUCCall) : 0)
        this.TATJsonReceived = this.getTimeFormat(t1);

        this.TATLinkSent = this.getTimeFormat(this.data.history[0].TATLinkSent != "" ? parseInt(this.data.history[0].TATLinkSent) : 0);

        let t2 = (this.data.history[0].TATLinkSubmitted != "" ? parseInt(this.data.history[0].TATLinkSubmitted) : 0) + (this.data.history[0].TATLinkFirstSave != "" ? parseInt(this.data.history[0].TATLinkFirstSave) : 0)
        this.TATLinkSubmitted = this.getTimeFormat(t2);

        this.TATPackageDelivered = this.getTimeFormat(this.data.history[0].TATPackageDelivered != "" ? parseInt(this.data.history[0].TATPackageDelivered) : 0);

        debugger
        this.total =
            (this.data.history[0].TATPackageReceived != "" ? parseInt(this.data.history[0].TATPackageReceived) : 0)
            + t1
            + (this.data.history[0].TATLinkSent != "" ? parseInt(this.data.history[0].TATLinkSent) : 0)
            + t2
            + (this.data.history[0].TATPackageDelivered != "" ? parseInt(this.data.history[0].TATPackageDelivered) : 0)


        this.TotalTime = this.getTimeFormat(this.total);

        // let ele =  document.getElementById('BookinIdealTime')

        // if(data.history.BookinIdealTimeEnd == null){
        //     ele!.classList.add("hideField")   
        // }
        // else{
        //     ele!.classList.add("showField")   
        // }
    }

    public getTimeFormat(second: any) {
        let day = Math.floor(second / (24 * 3600));

        second = second % (24 * 3600);
        let hour = Math.floor(second / 3600);

        second %= 3600;
        let minutes = Math.floor(second / 60);

        second %= 60;
        let seconds = Math.floor(second);

        // console.log(day + " " + "days " + hour + " " + "hours " + minutes + " " +
        // "minutes " + seconds + " " + "seconds ");

        return (day + " : " + hour + " : " + minutes + " : " + seconds);

    }



    onNoClick(): void {
        this.dialogRef.close(this.disabledEdit);
        // debugger
        // console.log(this.data);

        // // // // console.log("no click")
    }


    // public enabledOk(){
    //   let okButton = document.getElementById('okButton');
    //   okButton.setAttribute('disabled','false');
    // }

    okButton() {
        // // // // console.log('close details');
    }

}


@Component({
    selector: 'scoreCardInfo',
    templateUrl: 'scorecard.html',
})

export class ScoreCard {

    public disabledEdit: boolean = false;

    // public primaryXAxis!: Object;
    // public primaryYAxis!: Object;
    // public chartData!: Object[];
    // public chartData2!: Object[];
    // public marker!: Object;
    // public palette!: string[];
    // public legendSettings!: Object;


    @Output() getDisabledEditStatus = new EventEmitter<boolean>();


    constructor(
        private dialogRef: MatDialogRef<ScoreCard>,
        @Inject(MAT_DIALOG_DATA) public data: any, public apiService: ApiService) { }



    onNoClick(): void {
        this.dialogRef.close(this.disabledEdit);
    }


    // public enabledOk(){
    //   let okButton = document.getElementById('okButton');
    //   okButton.setAttribute('disabled','false');
    // }

    okButton() {
        // // // // console.log('close details');
    }
}

