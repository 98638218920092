<!-- <h1>Side bar works</h1> -->
<div class="control-section">
    <!-- sample level element  -->
    <div class="col-lg-12 col-sm-12 col-md-12 center">
        Click/Touch the button to view the sample
    </div>
    <div class="col-lg-12 col-sm-12 col-md-12 center">
        <a class="e-btn" id="newTab" (click)="newTabClick()" target="_blank">Open in new Tab</a>
    </div>
    <!-- sample level element  -->
    <div id="wrapper">
        <div class="col-lg-12 col-sm-12 col-md-12" id="sidebar-section">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <!--header-section  declaration -->
                <div class="header-section dock-menu" id="header">
                    <div id="navigationBar" class="row">
                        <div class="col-4 d-flex justify-content-start">
                            <div id="hamburger" class="icon-menu icon list mt-1 mx-3" (click)="openClick()"></div>
                            <!-- <img src="assets/images/company_logo_transparent.png"
                            class="rounded my-2 d-block navbarLogo"> -->
                            <!-- <div class="icons mt-1 mx-4">
                                <div class="e-icons th-small" (click)="openClick()"></div>
                            </div> -->
                        </div>
                        <div class="col-4 d-flex justify-content-center">
                            <img src="assets/images/company_logo_transparent.png"
                                class="rounded my-2 d-block navbarLogo">
                        </div>
                        <div class="col-4 d-flex justify-content-end">
                            <input type="text" placeholder="Search" id="searchBar1" class="search-icon list">
                            <div id='avatar'>
                                <!-- <img class="image" src="" alt="avatar" /> -->
                                <span class="e-avatar e-avatar-small e-avatar-circle mt-2 mx-1">SJ</span>
                            </div>
                        </div>
                        <!-- <li class="right-header list">
                            <div class="horizontal-menu"> -->
                        <!-- Menu element declaration -->
                        <!-- <ejs-menu [items]='AccountMenuItem' cssClass='dock-menu'></ejs-menu>
                            </div>
                        </li>
                        <li class="right-header list support">Support</li>
                        <li class="right-header list tour">Tour</li> -->
                    </div>
                </div>
                <!--end of header-section  declaration -->
                <!-- sidebar element declaration -->
                <ejs-sidebar id="sidebar-menu" class="dock-menu" #sidebarMenuInstance [enableDock]='enableDock'
                    [width]='width' [dockSize]='dockSize' [mediaQuery]='mediaQuery' [target]='target'>
                    <div class="main-menu">
                        <!-- <p class="main-menu-header">MAIN</p> -->
                        <!-- Menu element declaration -->
                        <ejs-menu [items]='menuItems' orientation='Vertical' cssClass='dock-menu' style="color:black"></ejs-menu>
                    </div>
                    <!-- <div class="action">
                        <p class="main-menu-header">ACTION</p>
                        <button class="e-btn action-btn" id="action-button">+ Button</button>
                    </div> -->
                </ejs-sidebar>
                <!-- end of sidebar element -->

                <!-- main-content declaration -->
                <div class="main-content" id="maintext">
                    <div id="sidebar-responsive-content" class="">

                        <div class="row">
                            <!-- <p>dfjdasfklsjlkfjlkasdjfkljs<br>al<br>k<br>d<br>j<br>f<br>sadddd<br>d<br>dd<br>d<br>d<br>d<br>d<br>d<br>d<br>dddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd<br></p> -->
                            <div class="col-2">

                            </div>
                            <div class="col-8 d-flex justify-content-center">

                                <img src="assets\images\sampling_page.png" class="middleContentImg mt-5 align-self-baselin">
                            </div>
                            <div class="col-2">

                            </div>
                        </div>
                        <footer id="footerContent6" class="">
                            <img src="assets/images/company_logo_transparent.png" class="rounded mx-auto d-block mb-3 toolbarLogo">
                            <p>Powered by PubMate. IN. An iCodex Product.<br> All rights reserved by iCodex 2021</p>
                        </footer>
                    </div>
                    <div class="sidebar-menu-content">
                        <!-- <div> Responsive Sidebar wi<br>th Mfkajsdkf<br>ljaskljflk<br>asjdlk;fja<br>skldjfk<br>j<br>as<br>d<br>l<br>k<br>f<br>j<br>lkasjdfkjasdk<br>;<br>l<br>jflkajsenu<br></div> -->
                        <!-- <footer id="footerContent6" class="">
                            <img src="assets/images/company_logo_transparent.png" class="rounded mx-auto d-block mb-3 toolbarLogo">
                            <p>Powered by PubMate. IN. An iCodex Product.<br> All rights reserved by iCodex 2021</p>
                        </footer> -->
                    </div>
                </div>
                <!-- end of main-content -->


            </div>
        </div>
    </div>
</div>