<main>
    <div class="container text-center">
        <!-- <img src="assets/images/company_logo_transparent.png" class="rounded mx-auto d-block mb-3 Logo"> -->
        <div class='heading my-5' *ngIf="isMsg==true" > <br>Downloading Excel, Please Wait... </div>
        <div class='heading my-5' *ngIf="isMsg==false" > <br>Excel is Downloaded </div>
        <br>
        <div class='msg'>
            <!-- <img src="../../../assets/images/please-wait.gif" alt=""> -->
            <div class="progress">
                <div id="progressBar" class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" ></div>
              </div>
              <!-- {{value}} -->
            <br>
            <!-- <div class="spinner-border spinner-border-lg spinner" *ngIf="isMsg==true" role="status" aria-hidden="false"></div> -->
            
        </div>

       
    </div>
    </main>
    <footer id="footerContent5">
        <!-- <img src="assets/images/company_logo_transparent.png" class="rounded mx-auto d-block mb-3 toolbarLogo"> -->
        <p>Powered by PubMate. IN. An iCodex Product.<br> All rights reserved by iCodex 2021</p>
    </footer>
