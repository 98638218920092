import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './Components/account/account.component';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './Components/forgot-password/forgot-password.component';
import { LoadingPageComponent } from './Components/loading-page/loading-page.component';
import { LoginComponent } from './Components/login/login.component';
import { PageNotFoundComponent } from './Components/page-not-found/page-not-found.component';
import { ResetPasswordComponent } from './Components/reset-password/reset-password.component';
import { ScoreCardComponent } from './Components/score-card/score-card.component';
import { SignupComponent } from './Components/signup/signup.component';
import { SupportComponent } from './Components/support/support.component';
import { UserServicesComponent } from './Components/user-services/user-services.component';
import { AuthguardGuard } from './guard/authguard.guard';

const routes: Routes = [
  {
    path:'log-in',
    component: LoginComponent
  },
  {
    path:'sign-up',
    component: SignupComponent
  },
  {
    path:'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path:'reset-password',
    component: ResetPasswordComponent
  },
  {
    path:'dashboard',
    component: DashboardComponent,
    canActivate:[AuthguardGuard]
  },
  {
    path:'loading-page',
    component:  LoadingPageComponent,
  },
 
  {
    path:'support',
    component: SupportComponent,
    canActivate:[AuthguardGuard]
  },
  {
    
    path:'userServices',
    component:UserServicesComponent,
  },
  {
    path:'scorecard',
    component: ScoreCardComponent,
  },
  {path: '',redirectTo:'log-in',pathMatch:'full'},
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
