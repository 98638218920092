import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Config } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class ApiService {


  public allowUser: boolean = false;
  public excelStatus: any = false;
  public excelPercentage: any = 0;

  headers = new HttpHeaders({
    'eAssistantXApiKey': environment.apiKeyValue,
  });


  // public baseUrl = "https://eassistantreportsservice.pubmate.in"     //live service
   public baseUrl = "https://qaeassistantreportservice.pubmate.co.in"   //uat service
  //  public baseUrl = "https://uateassistantreportsservices.icodex.in"   //uat service
  // public baseUrl = "https://stgeassistantreportservice.icodex.in/"
    //  public baseUrl = "https://testeassistantreportsservice.icodex.in/"
  //  public baseUrl = "https://deveassistantreportservice.icodex.in/" 
  //  public baseUrl = "http://192.168.1.131:809" //Developement service
                        

  constructor(private http: HttpClient) { }

  public setexcelStatus(excelStatus: any, excelPercentage: any) {
    // debugger

    this.excelStatus = excelStatus;
    this.excelPercentage = excelPercentage;
    localStorage.setItem("excelPercentage", this.excelPercentage)

  }
  public getexcelStatus() {
    return [this.excelStatus, this.excelPercentage]
  }

  // http://localhost:1000/report/getreportdata?PageNumber=6&PageSize=10

  public getData(pageNum: any, search: any, clientId: any, orderDirection: any, orderCol: any, pageSize: any): Observable<any> {
    let url = `${this.baseUrl}/report/getreportdata?PageNumber=${pageNum}&PageSize=${pageSize}`
    // return this.http.get(url );
    // let headers = new HttpHeaders({
    //   'eAssistantXApiKey': environment.apiKeyValue,
    // });
    let option = { headers: this.headers };
    let obj = {
      "clientId": clientId,
      "journalAbrv": "",
      "statusType": "",
      "fromDate": "",
      "toDate": "",
      "search": search,
      "isExcel": false,
      "OrderDirection": orderDirection,
      "OrderByColumn": orderCol,
    }

    // console.log(obj);
    // // // console.log('api object: ', obj)

    return this.http.post(url, obj);

    // return this.http.get(this.tempurl);
  }

  public uploadInputFile(InPutFile: File): Observable<any> {

    let inputFileUploadUrl = "https://uateassistantservices.icodex.in/eassistant/input/V2/file"
    let formData = new FormData();
    formData.append("InPutFile", InPutFile);

    // let headers = new HttpHeaders({
    //   "eAssistantXApiKey": "TOPS00015tgb^YHN&*L1KGBO24UI#AB",
    //   "type": "VENDOR"
    // })
    let headers = new HttpHeaders({
      "eAssistantXApiKey": environment.apiKeyValue,
      "type": "VENDOR"
    })
    let options = {
      headers: headers,
      reportProgress: true,
      observe: 'events'

    }
    return this.http.post(inputFileUploadUrl, formData, {
      headers: headers,
      reportProgress: true,
      observe: 'events'

    });

  }

  // public upload(file: File): Observable<HttpEvent<any>> {
  //   const formData: FormData = new FormData();

  //   formData.append('file', file);

  //   const req = new HttpRequest('POST', `${this.baseUrl}/upload`, formData, {
  //     reportProgress: true,
  //     responseType: 'json'
  //   });

  //   return this.http.request(req);
  // }

  public getVendors(): Observable<any> {
    let option = { headers: this.headers };
    let url = `${this.baseUrl}/report/allusers`
    return this.http.get(url);
    // return this.http.post(url,obj);
    // return this.http.get(this.tempurl);
  }

  
  public downloadOutputPackage(jobId:any): Observable<any> {
    let option = { headers: this.headers };
    let url = `${this.baseUrl}/support/output/${jobId}`
    return this.http.get(url);
  }

  public getJournals(userId: any): Observable<any> {
    let option = { headers: this.headers };
    let url = `${this.baseUrl}/report/getjournals/${userId}`
    return this.http.get(url);

    // return this.http.post(url,obj);

    // return this.http.get(this.tempurl);
  }

  public getStatus(): Observable<any> {
    let option = { headers: this.headers };
    let url = `${this.baseUrl}/report/getstatus`
    return this.http.get(url);

    // return this.http.post(url,obj);

    // return this.http.get(this.tempurl);
  }


  public getPackage1Count(clienId: any, packageNo: any, fromDate: any, toDate: any, journalAbrv: any): Observable<any> {
    // debugger
    let option = { headers: this.headers };

    let url = `${this.baseUrl}/report/packagecount`

    // 
    let obj = {
      "clientId": clienId,
      "CardType": packageNo,
      "fromDate": fromDate,
      "toDate": toDate,
      "journalAbrv": journalAbrv
    }
    // console.log('********************* obj', obj)
    // console.log('********************* url', url)
    return this.http.post(url, obj,);

    // return this.http.get(url );

    // return this.http.get(this.tempurl);
  }

  public getPackage2Count(clienId: any, packageNo: any, fromDate: any, toDate: any, journalAbrv: any): Observable<any> {
    let option = { headers: this.headers };

    let url = `${this.baseUrl}/report/packagecount`

    let obj = {
      "clientId": clienId,
      "CardType": packageNo,
      "fromDate": fromDate,
      "toDate": toDate,
      "journalAbrv": journalAbrv
    }
    // console.log('********************* obj', obj)
    return this.http.post(url, obj,);

    // return this.http.get(url );

    // return this.http.get(this.tempurl);
  }
  public getPackage3Count(clienId: any, packageNo: any, fromDate: any, toDate: any, journalAbrv: any): Observable<any> {
    let option = { headers: this.headers };

    let url = `${this.baseUrl}/report/packagecount`

    let obj = {
      "clientId": clienId,
      "CardType": packageNo,
      "fromDate": fromDate,
      "toDate": toDate,
      "journalAbrv": journalAbrv
    }
    // console.log('********************* obj', obj)
    return this.http.post(url, obj,);

    // return this.http.get(url );

    // return this.http.get(this.tempurl);
  }
  public getPackage4Count(clienId: any, packageNo: any, fromDate: any, toDate: any, journalAbrv: any): Observable<any> {
    let option = { headers: this.headers };

    let url = `${this.baseUrl}/report/packagecount`

    let obj = {
      "clientId": clienId,
      "CardType": packageNo,
      "fromDate": fromDate,
      "toDate": toDate,
      "journalAbrv": journalAbrv
    }
    return this.http.post(url, obj,);

    // return this.http.get(url );

    // return this.http.get(this.tempurl);
  }
  public getPackage5Count(clienId: any, packageNo: any, fromDate: any, toDate: any, journalAbrv: any): Observable<any> {
    let option = { headers: this.headers };

    let url = `${this.baseUrl}/report/packagecount`
    let obj = {
      "clientId": clienId,
      "CardType": packageNo,
      "fromDate": fromDate,
      "toDate": toDate,
      "journalAbrv": journalAbrv
    }
    return this.http.post(url, obj,);
    // return this.http.get(url );
    // return this.http.get(this.tempurl);
  }
  public getPackage6Count(clienId: any, packageNo: any, fromDate: any, toDate: any, journalAbrv: any): Observable<any> {
    let option = { headers: this.headers };

    let url = `${this.baseUrl}/report/packagecount`

    let obj = {
      "clientId": clienId,
      "CardType": packageNo,
      "fromDate": fromDate,
      "toDate": toDate,
      "journalAbrv": journalAbrv
    }
    return this.http.post(url, obj,);

    // return this.http.get(url );

    // return this.http.get(this.tempurl);
  }
  public getPackage7Count(clienId: any, packageNo: any, fromDate: any, toDate: any, journalAbrv: any): Observable<any> {
    let option = { headers: this.headers };

    let url = `${this.baseUrl}/report/packagecount`

    let obj = {
      "clientId": clienId,
      "CardType": packageNo,
      "fromDate": fromDate,
      "toDate": toDate,
      "journalAbrv": journalAbrv
    }
    return this.http.post(url, obj,);
    // return this.http.get(url );
    // return this.http.get(this.tempurl);
  }
  public getPackage8Count(clienId: any, packageNo: any, fromDate: any, toDate: any, journalAbrv: any): Observable<any> {
    let option = { headers: this.headers };

    let url = `${this.baseUrl}/report/packagecount`

    let obj = {
      "clientId": clienId,
      "CardType": packageNo,
      "fromDate": fromDate,
      "toDate": toDate,
      "journalAbrv": journalAbrv
    }
    return this.http.post(url, obj,);

    // return this.http.get(url );

    // return this.http.get(this.tempurl);
  }
  public getPackage9Count(clienId: any, packageNo: any, fromDate: any, toDate: any, journalAbrv: any): Observable<any> {
    let option = { headers: this.headers };

    let url = `${this.baseUrl}/report/packagecount`

    let obj = {
      "clientId": clienId,
      "CardType": packageNo,
      "fromDate": fromDate,
      "toDate": toDate,
      "journalAbrv": journalAbrv
    }
    return this.http.post(url, obj,);

    // return this.http.get(url );

    // return this.http.get(this.tempurl);
  }
  public getPackage10Count(clienId: any, packageNo: any, fromDate: any, toDate: any, journalAbrv: any): Observable<any> {
    let option = { headers: this.headers };

    let url = `${this.baseUrl}/report/packagecount`

    let obj = {
      "clientId": clienId,
      "CardType": packageNo,
      "fromDate": fromDate,
      "toDate": toDate,
      "journalAbrv": journalAbrv
    }
    return this.http.post(url, obj,);

    // return this.http.get(url );

    // return this.http.get(this.tempurl);
  }
  public getPackage11Count(clienId: any, packageNo: any, fromDate: any, toDate: any, journalAbrv: any): Observable<any> {
    let option = { headers: this.headers };

    let url = `${this.baseUrl}/report/packagecount`

    let obj = {
      "clientId": clienId,
      "CardType": packageNo,
      "fromDate": fromDate,
      "toDate": toDate,
      "journalAbrv": journalAbrv
    }
    return this.http.post(url, obj,);

    // return this.http.get(url );

    // return this.http.get(this.tempurl);
  }
  public getPackage12Count(clienId: any, packageNo: any, fromDate: any, toDate: any, journalAbrv: any): Observable<any> {
    let option = { headers: this.headers };

    let url = `${this.baseUrl}/report/packagecount`

    let obj = {
      "clientId": clienId,
      "CardType": packageNo,
      "fromDate": fromDate,
      "toDate": toDate,
      "journalAbrv": journalAbrv
    }
    return this.http.post(url, obj,);

    // return this.http.get(url );

    // return this.http.get(this.tempurl);
  }

  public getConfigResponse(pageNum: any, pageSize: any): Observable<HttpResponse<Config>> {

    let url = `${this.baseUrl}/report/getreportdata?PageNumber=${pageNum}&PageSize=${pageSize}`


    let obj = {
      "clientId": 0,
      "journalAbrv": "",
      "statusType": "",
      "fromDate": "",
      "toDate": "",
      "search": "",
      "isExcel": false,
    }

    return this.http.post<Config>(url, obj, { observe: 'response' });
  }

  public getExcelData(obj: any): Observable<any> {
    // debugger
    let option = { headers: this.headers };
    let url = `${this.baseUrl}/report/getreportdata?PageNumber=1&PageSize=10`

    // return this.http.get(url );

    return this.http.post(url, obj,);

    // return this.http.get(this.tempurl);
  }

  public getFilterData(pageNo: any, obj: any, pageSize: any): Observable<any> {
    let option = { headers: this.headers };
    let url = `${this.baseUrl}/report/getreportdata?PageNumber=${pageNo}&PageSize=${pageSize}`

    // console.log(pageNo)
    // return this.http.get(url );

    // // // console.log("************", obj);

    // debugger
    // console.log(obj)
    return this.http.post(url, obj,);

    // return this.http.get(this.tempurl);
  }

  public getCreadentials(userName: any, password: any): Observable<any> {
    let option = { headers: this.headers };
    // let url = `${this.baseUrl}/report/login?userName=${userName}&password=${password}`
    // return this.http.get(url );

    let url = `${this.baseUrl}/report/login`;
    let obj = {
      "FtpUserName": userName,
      "Password": password,
    }
    return this.http.post(url, obj,);
    // return this.http.get(this.tempurl);
  }


  // ${this.baseUrl}/report/getdetail/40994

  public getDetails(jobMasterId: any): Observable<any> {
    let option = { headers: this.headers };
    let url = `${this.baseUrl}/report/getdetail/${jobMasterId}`
    return this.http.get(url);

    // return this.http.post(url,obj);
    // return this.http.get(this.tempurl);
  }

  public getLink(jobId: any): Observable<any> {
    let option = { headers: this.headers };
    let url = `${this.baseUrl}/report/articlelink/${jobId}`
    return this.http.get(url);

    // return this.http.post(url,obj);
    // return this.http.get(this.tempurl);
  }

  public getStatusHistory(jobId: any): Observable<any> {
    let option = { headers: this.headers };
    let url = `${this.baseUrl}/report/getstatushistory/${jobId}`
    // let url = `http://localhost:4444/report/getstatushistory/${jobId}`
    return this.http.get(url);

    // return this.http.post(url,obj);
    // return this.http.get(this.tempurl);
  }

  public upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', `${this.baseUrl}/upload`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  getFiles(): Observable<any> {
    let option = { headers: this.headers };
    return this.http.get(`${this.baseUrl}/files`);
  }

  public getTotalData(clientId: any, journalAbrv: any, fromDate: any, toDate: any): Observable<any> {
    let option = { headers: this.headers };
    // let url = `${this.baseUrl}/report/gettotaldata?clientId=0&fromDate=${fromDate}&toDate=${toDate}`

    let url = `${this.baseUrl}/report/getScoreCard`
    // let url = `http://localhost:4444/report/gettotaldata`;

    // let obj = {
    //   clientId:parseInt(clientId),
    //   journalAbrv:journalAbrv,
    //   fromDate:fromDate,
    //   toDate:toDate,
    //   clientName:clientName
    // }
    // debugger

    let obj = {
      VendorId: parseInt(clientId),
      JournalId: journalAbrv,
      CreatedFrom: fromDate,
      CreatedTo: toDate,
    }

    // console.log(obj)
    // return this.http.get(url );

    return this.http.post(url, obj,);
    // return this.http.get(this.tempurl);
  }

  public getTatAverage(): Observable<any> {
    let option = { headers: this.headers };
    let url = `${this.baseUrl}/report/tataverage?jobId=`
    // let url = `http://localhost:4444/report/tataverage?jobId=`
    return this.http.get(url);

    // return this.http.post(url,obj);
    // return this.http.get(this.tempurl);
  }

  public getJornalsReport(clientId: any): Observable<any> {
    let option = { headers: this.headers };
    let url = `${this.baseUrl}/journals/get/${clientId}`
    // let url = `http://localhost:4444/report/tataverage?jobId=`

    return this.http.get(url, {
      reportProgress: true,
      observe: 'events'

    });

    // return this.http.post(url,obj);
    // return this.http.get(this.tempurl);
  }


  public getAuthorquery(startDate: any, endDate: any): Observable<any> {
    let option = { headers: this.headers };
    let url = `${this.baseUrl}/authorquery/get/${startDate}/${endDate}`
    // let url = `http://localhost:4444/report/tataverage?jobId=`
    return this.http.get(url);

    // return this.http.post(url,obj);
    // return this.http.get(this.tempurl);
  }

  public setLoginStatus() {
    this.allowUser = true
  }

  public getLoginStatus() {
    return this.allowUser
  }

  getLocalJsonData(): Observable<any> {
    return this.http.get("assets/data.json");
  }

  getFullExcelData(clientId: any, statusId: any, journalCode: any, fromDate: any, toDate: any): Observable<any> {
    // debugger
    let obj = {
      "StatusId": statusId,
      "CreatedFrom": fromDate,
      "CreatedTo": toDate,
      "VendorId": clientId,
      "JournalCode": journalCode
    }
    // debugger
    console.log(obj);
    let url = `${this.baseUrl}/report/fData`

    return this.http.post(url, obj, {
      reportProgress: true,
      observe: 'events'

    })
  }
}
