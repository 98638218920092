<main>
<div class="container">
    <div class="row mt-5">
        <div class="col-4 mx-auto" [ngClass]="(login != 'waiting') ? 'showField' : 'hideField'">
            <div tabindex="0" class="e-card" id="basic">
                <!-- <img src="assets/images/company_logo_transparent.png" class="rounded mx-auto d-block mt-2 mb-3"> -->
                <!-- <img src="assets/images/eAssistant Portal Logo.png" class="rounded mx-auto d-block mb-3 Logo"> -->
                <!-- <h1 class="text-center" style="color: #73879C;">eAssistant</h1> -->
                <div class="e-card-header text-center">
                    <div class="e-card-header-caption">
                        <div class="e-card-title">
                            <!-- <h4 class="heading">Sign In</h4> -->
                            <h4 class="heading">eAssistant - Sign In</h4>
                        </div>
                    </div>
                </div>
                <div class="e-card-content">
                    <div class="wrap">
                        <div class="e-float-input e-input-group e-success" id="UserName">
                            <input type="text" id='txtUserName' required> 
                            <span class="e-float-line"></span>
                            <label class="e-float-text">User Name</label>
                        </div>
                        <div class="e-float-input e-input-group e-success" id="Password">
                            <input type="password" id='password' required>
                            <span class="e-float-line"></span>
                            <label class="e-float-text">Password</label>
                        </div>
                        <div class="login-captcha mt-3">
                            

                            
                                <!-- <div class="e-float-input e-input-group captchaCol e-success">
                                    <input type="text" id="txtCompare1" required />
                                    <span class="e-float-line"></span>
                                    <label class="e-float-text">Enter Captcha</label>
                                </div>
                                <div class="card mt-2 captchaCol" style="height: 2.5em;">
                                    <input type="" class="blur noselect" id="txtCaptcha1" onmousedown="return false;"
                                        onselectstart='return false;' />
                                </div> -->
                            
                        </div>
                    </div>

                </div>
                <div class="row mx-auto mb-3">
                    <!-- (click)="validateCaptcha1()" -->
                    <button type="button" id="SignIn-btn" class="btn btn-success" (click)="validateCredentials()">Sign In</button>
                </div>

                <!-- <div class="link mx-auto text-center">
                    Don't have an account? <a [routerLink]="['/sign-up']" href="#">Sign Up</a>
                    <br>
                    <a [routerLink]="['/forgot-password']" href="#">Forgot Password?</a>
                </div> -->
            </div>
        </div>

        <div class="col-4 mx-auto mt-5" [ngClass]="(login == 'waiting') ? 'showField' : 'hideField'">
            <!-- <h1>Waiting</h1> -->
            <img src="assets/images/loading.gif" class="rounded mx-auto d-block mt-2 mb-3">
        </div>
    </div>


</div>
</main>
<footer id="footerContent1">
    <!-- <img src="assets/images/company_logo_transparent.png" class="rounded mx-auto d-block mb-3 toolbarLogo"> -->
    <p>Powered by PubMate. IN. An iCodex Product.<br> All rights reserved by iCodex 2021</p>
</footer>