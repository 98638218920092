import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.GenerateCaptcha2();
    document.getElementById("txtFirstName")!.focus();
  }

  private GenerateCaptcha2() {  
    var chr1 = Math.ceil(Math.random() * 10) + '';  
    var chr2 = Math.ceil(Math.random() * 10) + '';  
    var chr3 = Math.ceil(Math.random() * 10) + '';  

    var str = new Array(4).join().replace(/(.|$)/g, function () { return ((Math.random() * 36) | 0).toString(36)[Math.random() < .5 ? "toString" : "toUpperCase"](); });  
    var captchaCode = str + chr1 + ' ' + chr2 + ' ' + chr3; 
    const txtCaptcha =  document.getElementById("txtCaptcha2") as HTMLInputElement;
    txtCaptcha.value = captchaCode  
}
}
