import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
// import { } from '../dashboard/dashboard.component'
@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss']
})
export class LoadingPageComponent implements OnInit {

  constructor(private apiService: ApiService) { }
  // public excelstatus: any = false;
  // public excelpercentage: any = 0;
  public value: any = 0;
  public isMsg: boolean = true;

  ngOnInit(): void {
    
    setInterval(() => {
      // this.excelstatus = this.apiService.getexcelStatus()[0]
      // this.excelpercentage = this.apiService.getexcelStatus()[1]
      this.value = localStorage.getItem("excelPercentage");
      this.value = Math.floor(this.value);
      (<HTMLInputElement>document.getElementById("progressBar")).style.width = this.value + '%'
      if (this.value == 100) {
        this.isMsg = false;
        setTimeout(()=>{
          localStorage.setItem("excelPercentage","0");
          this.isMsg = true
          window.close();

        },5000)
      }
      else {
        this.isMsg = true;
      }
     console.log(this.isMsg)
     console.log(this.value)
      // console.log(this.excelstatus)
      // console.log(this.excelpercentage)
    }, 500)
   



  }


}
