<!-- <p>score-card works!</p> -->

<div class="container">
    <!-- <h1 class="text-center">Score Card</h1> -->

    <div class="row">


      <div class="row mb-5">
        <div class="col-6">

          <table class="table table-bordered ">
          <thead>
            <tr>
              <th class="text-center" scope="col" colspan="2">SmD KPI</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Precision</td>
              <td>{{apiData[0].Precision}}%</td>
            </tr>
            <tr>
              <td>Recall</td>
              <td>{{apiData[0].Recall}}%</td>
            </tr>
            <tr>
              <td>Accuracy</td>
              <td>{{apiData[0].Accuracy}}%</td>
            </tr>
          </tbody>
        </table>

          
          <!-- <div id="gauge_chart" [chartData]="gauge_ChartData" [chartOptions]="gauge_ChartOptions" chartType="Gauge"
            GoogleChart></div>
            <h4 class="gaugeChartName">SmD KPI</h4> -->
        </div>
        <div class="col-6 " style="margin-top: -19px;">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th class="text-center" scope="col" colspan="2">Formula</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>precision</td>
                <td>TP/(TP+FP)</td>
              </tr>
              <tr>
                <td>Recall</td>
                <td>TP/(TP+FN)</td>
              </tr>
              <tr>
                <td>Accuracy</td>
                <td>(TP+TN)/(TP+FP+TN+FN)</td>
              </tr>
              <tr>
                <td scope="col" colspan="2">TP:True positive,&nbsp;&nbsp; TN:True negative,&nbsp;&nbsp; FP:False
                  positive,&nbsp;&nbsp; FN:False negative</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <div class="col-3">
        <h5>precision=TP/(TP+FP)</h5>
        <h5>Recall=TP/(TP+FN)</h5>
        <h5>Accuracy=(TP+TN)/(TP+FP+TN+FN)</h5>
      </div>
      <div class="col-3">
        <h5>TP-True Positive</h5>
        <h5>TN-True Negative </h5>
        <h5>FP-False Positive </h5>
        <h5>FN-False Negative </h5>
      </div> -->

      </div>

      <div class="col-2 justify-content-between row justify-content-between" id="info">
        <span>
          <b>Vendor:</b> {{vendorName == "undefined" || vendorName == "Admin"? "All Vendors" : vendorName}}
        </span>
        <span *ngIf="journalAbrv != ''">
          <b>Journal:</b> {{journalAbrv}}
        </span>
      </div>
      <div class="col-2 justify-content-between row justify-content-between" id="date">
        <span *ngIf="fromDate != ''">
          <b>From Date:</b> {{fromDate}}
        </span>
        <span *ngIf="toDate != ''">
          <b>To Date:</b> {{toDate}}
        </span>
      </div>
      <h5 class="text-center col-5">Component Level KPI</h5>
    </div>
    
    <ejs-chart id="chart-container" width="900px" [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis'
      [palettes]='palette' [legendSettings]='legendSettings'>
      <e-series-collection>
        <e-series [dataSource]='chartData' type='Column' xName='type' yName='value' name='Recall' [marker]='marker'>
        </e-series>
        <e-series [dataSource]='chartData2' type='Column' xName='type' yName='value' name='Precision' [marker]='marker'>
        </e-series>
        <!-- <e-series [dataSource]='chartData3' type='Column' xName='type' yName='value' name='Accuracy' [marker]='marker'>
      </e-series> -->
      </e-series-collection>
    </ejs-chart>

    <!-- d<br>hs<br>x<br>h<br>kj<br>s<br>b<br>x<br>bsbx<br>n<br>m<br>s<br>bxnbsnxbswnmbxnwsmbxnbsxnwbnxwbnxwbxn<br> -->

    <div class="row mt-5">
      <!-- [routerLink]="['/dashboard']" -->
      <!-- <button type="button" class="btn btn-primary col-1 mx-auto" (click)="closeScoreCard()">Close</button> -->
      <button type="button" class="btn btn-secondary col-1 mx-auto" data-bs-dismiss="modal">Close</button>
    </div>

</div>

