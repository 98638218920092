import { DatePipe } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { Workbook } from 'exceljs';


import * as fs from 'file-saver';
const ExcelJS = require('exceljs');

@Injectable({
  providedIn: 'root'
})

export class GenerateExcelService {

  public currentDate: any;
  constructor(private datePipe: DatePipe) { }


  stripHtml(html: any) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }
  async generateExcel(rawData: any, selectedCols: any[]) {
    debugger

    // ******************************************Report Data*********************************************
    let date = new Date();
    let month = date.getMonth() + 1
    this.currentDate = date.getDate() + "-" + month + "-" + date.getFullYear() + "  " + date.getHours() + ":" + date.getMinutes();
    const workbook = new Workbook();
    let revSelectedCols: any = [];

    for (let col of selectedCols) {
      revSelectedCols.push(col);
    }

    selectedCols = selectedCols.reverse();

    const title = `eAssistant Report      Created on: ${this.currentDate}`;

    let header1Cols = [
      'PackageReceivedOn',
      'CUCCalledOn',
      'JsonReceivedOn',
      'LinkSentOn',
      'LinkSubmittedOn',
      'LinkFirstSaveOn',
      'PackageDeliveredOn',
    ]

    let header2Cols = [
      'Table(s)',

      'Author(s)',
      'Affiliation(s)',
      'Reference(s)',

      'Word(s)',
      'Equation(s)',
      'Figure(s)',
      'KeyWord(s)',
      // 'Article Header ',
    ];

    let header3Cols = [
      'Article Header ',
      'Author And Contributor ',
      'Table(s) ',
      'Figure(s) ',
      'Reference(s)',
      'Author Query',
    ];

    let header4Cols = [
      // 'Author Query',
      'Article Id AQC',
      // 'Article DOI AQC',
      'Article Doi AQC',
      'Article Category AQC',
      'Article Type AQC',
      'Title AQC',
      'Abstract AQC',

      'Keyword AQC',
      'Funder Information AQC',
      // 'Funding information AQC',
      'Authors AQC',
      'Affiliation AQC',

      'Corresponding Author email AQC',
      'Editor AQC',
      'Figure AQC',
      'Table AQC',
      'Reference AQC',
      'Article Harmonization AQC',
      'Manuscript error',

    ];


    var header: any = [];
    debugger
    let heads: any = {
      "Workflow Events & System Performance Indicator": { Cols: header1Cols, Data: [] },
      "Manuscript Metadata Information": { Cols: header2Cols, Data: [] },
      "Number(s) of Mismatch Between Metadata & Manuscript": { Cols: header3Cols, Data: [] },
      "Author Query Count": { Cols: header4Cols, Data: [] },
    };

    for (let col of selectedCols) {
      if (!Object.keys(heads).includes(col.trim())) {
        header.splice(0, 0, col);
      }
    }

    debugger
    for (let col of revSelectedCols) {
      if (Object.keys(heads).includes(col.trim())) {
        // header.concat(heads[col.trim()].Cols);
        for (let head of heads[col.trim()].Cols) {
          header.push(head);
        // header.splice(0, 0, head);
        }
      }
    }

    let data = rawData[0];
    let authorQuery = rawData[2]

    const sheetData = []

    for (let j in authorQuery) {
      for (let k in data) {
        if (data[k].Id == authorQuery[j].JobMasterId) {
          if (authorQuery[j].AuthorQuery == 96) {
            debugger
          }
          if (authorQuery[j].AuthorQuery != 0 && authorQuery[j].AuthorQuery != '0') {
            console.log(authorQuery[j].AuthorQuery);
            data[k][authorQuery[j].Element.replace(/\s/g, "") + "Aq" + 'Count'] = authorQuery[j].AuthorQuery
            console.log(data[k][authorQuery[j].Element.replace(/\s/g, "") + "Aq" + 'Count']);
            data[k].isAuthoryQuery = "Yes"
          }
        }
      }
    }


    debugger
    for (let i in data) {

      try {
        if (data[i].Name != "Disabled&PendingForBilling") {
          let rowData = [];

          let header1Data = [
            data[i].PackageReceivedOn == null || data[i].PackageReceivedOn == '-' ? "-" : this.datePipe.transform(data[i].PackageReceivedOn, "yyyy-MM-dd h:mm:ss"),

            data[i].CUCCalledOn == null || data[i].CUCCalledOn == '-' ? "-" : this.datePipe.transform(data[i].CUCCalledOn, "yyyy-MM-dd h:mm:ss"),
            data[i].JsonReceivedOn == null || data[i].JsonReceivedOn == '-' ? "-" : this.datePipe.transform(data[i].JsonReceivedOn, "yyyy-MM-dd h:mm:ss"),

            data[i].LinkSentOn == null || data[i].LinkSentOn == '-' ? "-" : this.datePipe.transform(data[i].LinkSentOn, "yyyy-MM-dd h:mm:ss"),
            data[i].LinkSubmittedOn == null || data[i].LinkSubmittedOn == '-' ? "-" : this.datePipe.transform(data[i].LinkSubmittedOn, "yyyy-MM-dd h:mm:ss"),


            data[i].LinkFirstSaveOn == null || data[i].LinkFirstSaveOn == '-' ? "-" : this.datePipe.transform(data[i].LinkFirstSaveOn, "yyyy-MM-dd h:mm:ss"),
            data[i].PackageDeliveredOn == null || data[i].PackageDeliveredOn == '-' ? "-" : this.datePipe.transform(data[i].PackageDeliveredOn, "yyyy-MM-dd h:mm:ss")
          ];

          let header2Data = [
            data[i].Tables == null ? 0 : data[i].Tables,

            data[i].Authors == null ? 0 : data[i].Authors,
            data[i].Affiliations == null ? 0 : data[i].Affiliations,

            data[i].Reference == null ? 0 : data[i].Reference,
            data[i].Words == null ? 0 : data[i].Words,
            data[i].Equations == null ? 0 : data[i].Equations,

            data[i].MAFigures == null ? 0 : data[i].MAFigures,
            data[i].Keywords == null ? 0 : data[i].Keywords,
            // data[i].ArticleHeader == null ? 0 : data[i].ArticleHeader,
          ];

          let header3Data = [
            data[i].ArticleHeader == null ? 0 : data[i].ArticleHeader,
            data[i].AuthorContributors == null ? 0 : data[i].AuthorContributors,

            data[i].Tables == null ? 0 : data[i].Tables,
            data[i].MCFigures == null ? 0 : data[i].MCFigures,
            data[i].MCReference == null ? 0 : data[i].MCReference,
            data[i].isAuthoryQuery == "Yes" ? "Yes" : "No",

          ];

          let header4Data = [
            // data[i].isAuthoryQuery == "Yes" ? "Yes" : "No",
            data[i].ArticleIdAqCount && data[i].ArticleIdAqCount != '0' ? data[i].ArticleIdAqCount : '0',
            data[i].ArticleDoiAqCount && data[i].ArticleDoiAqCount != '0' ? data[i].ArticleDoiAqCount : '0',
            data[i].ArticleCategoryAqCount && data[i].ArticleCategoryAqCount != '0' ? data[i].ArticleCategoryAqCount : '0',

            data[i].ArticleTypeAqCount && data[i].ArticleTypeAqCount != '0' ? data[i].ArticleTypeAqCount : '0',
            data[i].TitleAqCount && data[i].TitleAqCount != '0' ? data[i].TitleAqCount : '0',
            data[i].AbstractAqCount && data[i].AbstractAqCount != '0' ? data[i].AbstractAqCount : '0',
            data[i].KeywordAqCount && data[i].KeywordAqCount != '0' ? data[i].KeywordAqCount : '0',


            data[i].FunderInformationAqCount && data[i].FunderInformationAqCount != '0' ? data[i].FunderInformationAqCount : '0',
            // data[i].FundinginformationAqCount && data[i].FundinginformationAqCount != '0' ? data[i].FundinginformationAqCount : '0',
            data[i].AuthorAqCount && data[i].AuthorAqCount != '0' ? data[i].AuthorAqCount : '0',
            data[i].AffiliationAqCount && data[i].AffiliationAqCount != '0' ? data[i].AffiliationAqCount : '0',
            data[i].CorrespondingAuthorEmailAqCount && data[i].CorrespondingAuthorEmailAqCount != '0' ? data[i].CorrespondingAuthorEmailAqCount : '0',

            data[i].EditorAqCount && data[i].EditorAqCount != '0' ? data[i].EditorAqCount : '0',
            data[i].FigureAqCount && data[i].FigureAqCount != '0' ? data[i].FigureAqCount : '0',
            data[i].TableAqCount && data[i].TableAqCount != '0' ? data[i].TableAqCount : '0',
            data[i].ReferenceAqCount && data[i].ReferenceAqCount != '0' ? data[i].ReferenceAqCount : '0',

            data[i].ArticleHarmonizationAqCount && data[i].ArticleHarmonizationAqCount != '0' ? data[i].ArticleHarmonizationAqCount : '0',
            data[i].ManuScriptError == null ? '-' : data[i].ManuScriptError,
          ];

          debugger
          heads["Workflow Events & System Performance Indicator"].Data = header1Data;
          heads["Manuscript Metadata Information"].Data = header2Data;
          heads["Number(s) of Mismatch Between Metadata & Manuscript"].Data = header3Data;
          heads["Author Query Count"].Data = header4Data;
          let fixedData: any = []

          for (let col of revSelectedCols) {
            if (col == 'ID') {
              rowData.push(data[i].Id)
              continue
            }
            else if (col == 'Vendor') {
              debugger
              rowData.push(data[i].FtpUserName)
              continue
            }
            else if (col == 'Article Id') {
              rowData.push(data[i].ArticleId)
              continue
            }
            else if (col == 'Journal Code') {
              rowData.push(data[i].JournalAbrv)
              continue
            }
            else if (col == 'Editorial Reference Code') {
              rowData.push(data[i].EditorialReferenceCode)
              continue
            }
            else if (col == 'Input File') {
              rowData.push(data[i].InputFileName)
              continue
            }
            else if (col == 'Zip File') {
              rowData.push(data[i].ZipFileName)
              continue
            }
            else if (col == 'Booking-In Type') {
              rowData.push(data[i].IsManual == true ? 'Manual' : 'Regular')
              continue
            }
            else if (col == 'Request Id') {
              rowData.push(data[i].TPReferenceNumber)
              continue
            }
            else if (col == 'Job Id') {
              rowData.push(data[i].JobId)
              continue
            }
            else if (col == 'Status') {
              rowData.push(data[i].Name)
              continue
            }
            else if (col == 'Created On Date') {
              rowData.push(data[i].CreatedOn.split('T')[0])
              continue
            }
            else if (col == 'Created On Time') {
              rowData.push(data[i].CreatedOn.split('T')[1])
              continue
            }
            else if (col == 'Modified On Date') {
              rowData.push(data[i].ModifiedOn.split('T')[0])
              continue
            }
            else if (col == 'Modified On Time') {
              rowData.push(data[i].ModifiedOn.split('T')[1])
              continue
            }
            else if (col == 'Age') {
              rowData.push(data[i].Name.toLowerCase() != "jsoninvalid" ? data[i].Age : "-")
              continue
            }
          }

          // for (let item in fixedData) {
          //   rowData.push(fixedData[item]);
          // }
          debugger
          for (let col of revSelectedCols) {
            if (Object.keys(heads).includes(col.trim())) {
              // rowData.concat(heads[col.trim()].Data);
              for (let headData of heads[col.trim()].Data) {
                rowData.push(headData);
              }
            }
          }

          sheetData.push(rowData)
        }
      }
      catch (e) {
        console.log(data[i])
      }
    }
    console.log("reportdata", data)
    const worksheet = workbook.addWorksheet("eAssistant Report");

    //   // // Add Row and formatting
    const titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };

    worksheet.addRow([]);
    worksheet.addRow([]);

    const mainHeadingRow = worksheet.getRow(3);

    debugger
    for (let col of selectedCols) {
      if (Object.keys(heads).includes(col.trim())) {
          worksheet.mergeCells(3, (header.indexOf(heads[col.trim()].Cols[0]) + 1), 4, (header.indexOf(heads[col.trim()].Cols[heads[col.trim()].Cols.length - 1]) + 1));
          mainHeadingRow.getCell(header.indexOf(heads[col.trim()].Cols[0]) + 1).value = col;
          mainHeadingRow.getCell(header.indexOf(heads[col.trim()].Cols[0]) + 1).alignment = { horizontal: 'center' };
          mainHeadingRow.getCell(header.indexOf(heads[col.trim()].Cols[0]) + 1).font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
          mainHeadingRow.getCell(header.indexOf(heads[col.trim()].Cols[0]) + 1).border = {
            top: { style: 'thin', color: { argb: '#000000' } }, left: { style: 'thin', color: { argb: '#000000' } },
            bottom: { style: 'thin', color: { argb: '#000000' } }, right: { style: 'thin', color: { argb: '##000000' } }
          }
          worksheet.addRow([]);
      }
    }

    worksheet.addRow(header);

    for (let d in sheetData) {
      worksheet.addRow(sheetData[d])

    }

    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 30;
    worksheet.getColumn(8).width = 30;
    worksheet.getColumn(9).width = 30;
    worksheet.getColumn(10).width = 30;
    worksheet.getColumn(11).width = 30;
    worksheet.getColumn(12).width = 30;
    worksheet.getColumn(13).width = 30;
    worksheet.getColumn(14).width = 30;
    worksheet.getColumn(15).width = 30;
    worksheet.getColumn(16).width = 30;
    worksheet.getColumn(17).width = 30;
    worksheet.getColumn(18).width = 30;
    worksheet.getColumn(19).width = 30;
    worksheet.getColumn(20).width = 30;
    worksheet.getColumn(21).width = 30;
    worksheet.getColumn(22).width = 30;
    worksheet.getColumn(23).width = 20;
    worksheet.getColumn(24).width = 20;
    worksheet.getColumn(25).width = 20;
    worksheet.getColumn(26).width = 20;
    worksheet.getColumn(27).width = 20;
    worksheet.getColumn(28).width = 20;
    worksheet.getColumn(29).width = 20;
    worksheet.getColumn(30).width = 20;
    worksheet.getColumn(31).width = 20;
    worksheet.getColumn(32).width = 30;
    worksheet.getColumn(33).width = 30;
    worksheet.getColumn(34).width = 30;
    worksheet.getColumn(35).width = 30;
    worksheet.getColumn(36).width = 30;
    worksheet.getColumn(37).width = 20;
    worksheet.getColumn(38).width = 20;
    worksheet.getColumn(39).width = 20;
    worksheet.getColumn(40).width = 20;
    worksheet.getColumn(41).width = 20;
    worksheet.getColumn(42).width = 20;
    worksheet.getColumn(43).width = 20;
    worksheet.getColumn(44).width = 20;
    worksheet.getColumn(45).width = 20;
    worksheet.addRow([]);



    // ******************************************Journals Data*********************************************

    let date2 = new Date();
    let month2 = date2.getMonth() + 1
    this.currentDate = date2.getDate() + "-" + month2 + "-" + date2.getFullYear() + "  " + date2.getHours() + ":" + date2.getMinutes();
    // const workbook = new Workbook();

    const title2 = `Journals Report    Created on: ${this.currentDate}`;

    var header2 = ['Client Name', 'Journal Name', 'Created On'];

    let data2 = rawData[1];

    const sheetData2 = []

    for (let i in data2) {
      sheetData2.push([
        data2[i].Client, data2[i].JournalName, data2[i].CreatedOn
      ])
    }
    const worksheet2 = workbook.addWorksheet("Journals Report");

    //   // // Add Row and formatting
    const titleRow2 = worksheet2.addRow([title2]);
    titleRow2.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
    worksheet2.addRow([]);
    //   // const subTitleRow = worksheet.addRow(['Date : 06-09-2020']);

    //   // // Blank Row
    worksheet2.addRow([]);
    //   // // Add Header Row
    const headerRow2 = worksheet2.addRow(header2);

    for (let d in sheetData2) {
      worksheet2.addRow(sheetData2[d])
    }


    worksheet2.getColumn(1).width = 30;
    worksheet2.getColumn(2).width = 30;
    worksheet2.getColumn(3).width = 30;

    worksheet2.addRow([]);
    // // Generate Excel File with given name
    let fileName = "eAssistant Report " + this.currentDate + ".xlsx";
    workbook.xlsx.writeBuffer().then((sheetData: any) => {
      const finalExcel = new Blob([sheetData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(finalExcel, fileName);
    });
  }
}