<main>
<div class="container">

    <div class="row my-5">
        <div class="col-4 mx-auto">
            <div tabindex="0" class="e-card" id="basic">
                <img src="assets/images/company_logo_transparent.png" class="rounded mx-auto d-block mb-3 Logo">
                <div class="e-card-header text-center">
                    <div class="e-card-header-caption">
                        <div class="e-card-title">
                            <h4 class="heading">Forgot Password</h4>
                        </div>
                    </div>
                </div>
                <div class="e-card-content">
                    <div class="wrap">
                        <div class="e-float-input e-input-group e-success" id="registeredEmail">
                            <input type="email" id='txtRegisteredEmail' required />
                            <span class="e-float-line"></span>
                            <label class="e-float-text">Registered Email Id</label>
                        </div>

                    </div>

                </div>
                <div class="row mx-auto mb-3">
                    <button type="button" class="btn btn-success" [routerLink]="['/reset-password']">Send</button>
                </div>

                <div class="link mx-auto text-center">
                    <a [routerLink]="['/log-in']" href="#">Back to Log in</a>
                </div>
            </div>
        </div>
    </div>


</div>
</main>
<footer id="footerContent3">
    <img src="assets/images/company_logo_transparent.png" class="rounded mx-auto d-block mb-3 toolbarLogo">
    <p>Powered by PubMate. IN. An iCodex Product.<br> All rights reserved by iCodex 2021</p>
</footer>