<!-- <h1 mat-dialog-title>Do you really want to submit?</h1>
<div mat-dialog-content>
  <div class="input-group mb-3">
    <textarea rows="3" class="form-control" id="finalRemark" placeholder="Final assessment and Notes"  style="width: 100%;font-size: 1rem;" ></textarea>
  </div>
</div>
<div mat-dialog-actions class="row justify-content-between">
  <div class="col-1"></div>
  <button type="button" class="btn btn-primary col-3" (click)="onNoClick()">Cancel</button>
  <button type="button" class="btn btn-primary col-3 disabled" id="okButton" cdkFocusInitial  (click)="okButton()" >Ok</button>
  <div class="col-1"></div>
</div> -->
<div class="text-center" [ngClass]="(loader==false) ? 'showField' : 'hideField'" >

  <img src="assets/images/loading.gif" class="rounded mx-auto d-block mt-2 mb-3">

</div>

<div class="contaner-fluid" [ngClass]="(loader==true) ? 'showField' : 'hideField'">
 
  <h1 class="text-center"> Details</h1>
  <div class="row" >
    <div class="col-5 mx-auto">
      <ul class="list-group">
        <li class="list-group-item list-group-item-info text-center" aria-current="true">Number(s) of Mismatch Between
          Metadata &
          Manuscript</li>
        <li class="list-group-item" aria-current="true">
          <div class="row">
            <div class="col-6">
              Article Header
            </div>
            <div class="col-6">
              {{data.details.MetadataInformation != null ? data.details.MetadataInformation.ArticleHeader: '-' }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-6">
              Author(s) & Contributor(s)
            </div>
            <div class="col-6">
              {{data.details.MetadataInformation != null ? data.details.MetadataInformation.AuthorContributors: '-' }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-6">
              Table(s)
            </div>
            <div class="col-6">
              {{data.details.MetadataInformation != null ? data.details.MetadataInformation.TableMetadata: '-' }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-6">
              Figure(s)
            </div>
            <div class="col-6">
              {{data.details.MetadataInformation != null ? data.details.MetadataInformation.FigureMetadata: '-' }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-6">
              Reference(s)
            </div>
            <div class="col-6">
              {{data.details.MetadataInformation != null ? data.details.MetadataInformation.ReferenceMetadata: '-' }}
            </div>
          </div>
        </li>
      </ul>

    </div>
  </div>



  <ul class="list-group mt-3">
    <li class="list-group-item list-group-item-info text-center col-10 mx-auto" aria-current="true">
      Manuscript Metadata Information </li>
  </ul>
  <ul class="list-group col-10 mx-auto row list-group-horizontal">
    <li class="list-group-item col-6">
      <div class="row">
        <div class="col-6">
          No of Page(s)
        </div>
        <div class="col-6">
          {{data.details.ManuscriptAnalysis != null ? data.details.ManuscriptAnalysis.NoOfPages: '-' }}
        </div>
      </div>
    </li>
    <li class="list-group-item col-6">
      <div class="row">
        <div class="col-6">
          Table(s)
        </div>
        <div class="col-6">
          {{data.details.ManuscriptAnalysis != null ? data.details.ManuscriptAnalysis.TablesManuscript: '-' }}
        </div>
      </div>
    </li>
  </ul>
  <ul class="list-group col-10 mx-auto row list-group-horizontal">
    <li class="list-group-item col-6">
      <div class="row">
        <div class="col-6">
          Author(s)
        </div>
        <div class="col-6">
          {{data.details.ManuscriptAnalysis != null ? data.details.ManuscriptAnalysis.AuthorsManuscript: '-' }}
        </div>
      </div>
    </li>
    <li class="list-group-item col-6">
      <div class="row">
        <div class="col-6">
          Affiliation(s)
        </div>
        <div class="col-6">
          {{data.details.ManuscriptAnalysis != null ? data.details.ManuscriptAnalysis.AffiliationsManuscript: '-' }}
        </div>
      </div>
    </li>
  </ul>
  <ul class="list-group col-10 mx-auto row list-group-horizontal">
    <li class="list-group-item col-6">
      <div class="row">
        <div class="col-6">
          Reference(s)
        </div>
        <div class="col-6">
          {{data.details.ManuscriptAnalysis != null ? data.details.ManuscriptAnalysis.ReferencesManuscript: '-' }}
        </div>
      </div>
    </li>
    <li class="list-group-item col-6">
      <div class="row">
        <div class="col-6">
          Word Count
        </div>
        <div class="col-6">
          {{data.details.ManuscriptAnalysis != null ? data.details.ManuscriptAnalysis.Words: '-' }}
        </div>
      </div>
    </li>
  </ul>
  <ul class="list-group col-10 mx-auto row list-group-horizontal">
    <li class="list-group-item col-6">
      <div class="row">
        <div class="col-6">
          Equation(s)
        </div>
        <div class="col-6">
          {{data.details.ManuscriptAnalysis != null ? data.details.ManuscriptAnalysis.Equations: '-' }}
        </div>
      </div>
    </li>
    <li class="list-group-item col-6">
      <div class="row">
        <div class="col-6">
          Figure(s)
        </div>
        <div class="col-6">
          {{data.details.ManuscriptAnalysis != null ? data.details.ManuscriptAnalysis.Figures: '-' }}
        </div>
      </div>
    </li>
  </ul>
  <ul class="list-group col-10 mx-auto row list-group-horizontal">
    <li class="list-group-item col-6">
      <div class="row">
        <div class="col-6">
          Keyword(s)
        </div>
        <div class="col-6">
          {{data.details.ManuscriptAnalysis != null ? data.details.ManuscriptAnalysis.KeyWords: '-' }}
        </div>
      </div>
    </li>
    <li class="list-group-item col-6">
      <div class="row">
        <div class="col-6">

        </div>
        <div class="col-6">

        </div>
      </div>
    </li>
  </ul>




  <ul class="list-group mt-3">
    <li class="list-group-item list-group-item-info text-center col-10 mx-auto" aria-current="true">
      Workflow Events & System Performance Indicator
    </li>
  </ul>
  <div class="row">
    <div class="col-10 mx-auto">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col" style="padding-bottom: 40px;text-align: center;">Start Time</th>
            <th scope="col" style="padding-bottom: 40px;text-align: center;">End Time</th>
            <th scope="col">
              <p class="text-center">
                Total Time Taken<br>
                Day(s) - Hr(s) : Min(s) : Sec(s)
              </p>
            </th>
            <th scope="col" style="padding-bottom: 40px;text-align: center;">Remark</th>

          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Manuscript Received</th>
            <!-- <td><span  ng-if="data.history[0].ReceivedStart == null">-<br></span></td> -->

            <td><span>{{data.history[0].PackageReceivedOn!='' ? data.history[0].PackageReceivedOn: '-' }}</span></td>
            <td><span>{{data.history[0].PackageReceivedOn!='' ? data.history[0].PackageReceivedOn: '-' }}</span></td>
            <!-- <td ng-if="data.history[0].ReceivedStart == null">{{data.history[0].ReceivedEnd}}</td> -->
            <td>{{TATPackageReceived}}</td>
            <!-- <td ng-if="">{{data.history[0].ReceivedRemark}}</td> -->
            <td>Package received from vendor
            </td>
          </tr>
          <tr>
            <th scope="row">CUC Automation</th>
            <td><span>{{data.history[0].PackageReceivedOn!='' ? data.history[0].PackageReceivedOn: '-' }}</span></td>
            <td><span>{{data.history[0].JsonReceivedOn!='' ? data.history[0].JsonReceivedOn: '-' }}</span></td>
            <!-- <td ng-if="">{{data.history[0].CUCStart}}</td>
            <td ng-if="">{{data.history[0].CUCEnd}}</td> -->
            <td>{{TATJsonReceived}}</td>
            <!-- <td ng-if="">{{data.history[0].CUCRemark}}</td> -->
            <td>Time taken to receive JSON from CUC</td>


          </tr>
          <tr>
            <th scope="row">Bookin Link Sent</th>
            <td><span>{{data.history[0].JsonReceivedOn!='' ? data.history[0].JsonReceivedOn: '-' }}</span></td>
            <td><span>{{data.history[0].LinkSentOn!='' ? data.history[0].LinkSentOn: '-' }}</span></td>
            <!-- <td ng-if="data.history[0].BookinStart == null">-</td>
            <td ng-if="data.history[0].BookinEnd == null">{{data.history[0].BookinEnd}}</td> -->
            <td>{{TATLinkSent}}</td>
            <!-- <td ng-if="">{{data.history[0].LinkRemark}}</td> -->
            <td>Time taken for Bookin link to be sent to BA</td>


          </tr>
          <!-- *ngIf="data.history[0].BookinIdealTimeEnd != null" -->
          <tr>
            <!-- [ngClass]="(data.history[0].BookinIdealTimeEnd != null) ? 'showField' : 'hideField'" -->
            <!-- *ngIf = "BookinIdealTimeEnd != null" -->
            <th scope="row">Bookin Ideal Time</th>
            <td><span>{{data.history[0].LinkSentOn!='' ? data.history[0].LinkSentOn: '-' }}</span></td>
            <td><span>{{data.history[0].LinkFirstSaveOn!='' ? data.history[0].LinkFirstSaveOn: '-' }}</span></td>
            <td>{{TATLinkFirstSave}}</td>
            <!-- <td ng-if="">{{data.history[0].LinkRemark}}</td> -->
            <td>Time between link sent to BA till their first save</td>
          </tr>

          <tr>
            <th scope="row">Bookin In-Process</th>
            <td><span>{{data.history[0].LinkSentOn!='' ? data.history[0].LinkSentOn: '-' }}</span></td>
            <td><span>{{data.history[0].LinkSubmittedOn!='' ? data.history[0].LinkSubmittedOn: '-' }}</span></td>
            <!-- <td ng-if="">{{data.history[0].LinkFirstSaveStart}}</td>
            <td ng-if="">{{data.history[0].LinkFirstSaveEnd}}</td> -->
            <td>{{TATLinkSubmitted}}</td>
            <!-- <td ng-if="">{{data.history[0].LinkRemark}}</td> -->
            <td>Time taken for BA to submit the link</td>


          </tr>
          <tr>
            <th scope="row">Final Package Delivered</th>
            <td><span>{{data.history[0].LinkSubmittedOn!='' ? data.history[0].LinkSubmittedOn: '-' }}</span></td>
            <td><span>{{data.history[0].PackageDeliveredOn!='' ? data.history[0].PackageDeliveredOn: '-' }}</span></td>
            <!-- <td ng-if="">{{data.history[0].DelveredStart}}</td>
            <td ng-if="">{{data.history[0].DeliveredEnd}}</td> -->
            <td>{{TATPackageDelivered}}</td>
            <!-- <td ng-if="">{{data.history[0].DeliveredRemark}}</td> -->
            <td>Time between link submission and package delivery</td>


          </tr>
          <tr>
            <td></td>
            <td></td>
            <th scope="row">Total</th>
            <td ng-if="">{{TotalTime}}</td>
            <td>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>


  <div class="row">
    <div class="col-1 mt-3 mx-auto">
      <button type="button" class="btn btn-primary" (click)="onNoClick()">Close</button>
    </div>
  </div>
</div>




