import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './Components/login/login.component';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { enableRipple } from '@syncfusion/ej2-base';
import { SignupComponent } from './Components/signup/signup.component';
import { ForgotPasswordComponent } from './Components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './Components/reset-password/reset-password.component';
import { PageNotFoundComponent } from './Components/page-not-found/page-not-found.component';
import { AccountComponent } from './Components/account/account.component';
import { NgMatSearchBarModule } from 'ng-mat-search-bar';


import { ListViewAllModule } from '@syncfusion/ej2-angular-lists';
import { SidebarModule, MenuAllModule, TreeViewAllModule } from '@syncfusion/ej2-angular-navigations';
import { RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DashboardComponent, FinalRemark } from './Components/dashboard/dashboard.component';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { PageService, SortService, FilterService, GroupService } from '@syncfusion/ej2-angular-grids';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';

import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';

import { ApiService } from './Components/services/api.service';
import { HttpClientModule } from '@angular/common/http'

import { MatDialogModule } from '@angular/material/dialog';
import { ScoreCardComponent } from './Components/score-card/score-card.component';
import { ToastrModule } from 'ngx-toastr';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { ChartModule } from '@syncfusion/ej2-angular-charts';
import {
  CategoryService, DateTimeService, ScrollBarService, ColumnSeriesService, LineSeriesService,
  ChartAnnotationService, RangeColumnSeriesService, StackingColumnSeriesService,
  LegendService, TooltipService, DataLabelService
} from '@syncfusion/ej2-angular-charts';

import { CommonModule, DatePipe } from '@angular/common';
import { SupportComponent } from './Components/support/support.component';
import { UserServicesComponent } from './Components/user-services/user-services.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingPageComponent } from './Components/loading-page/loading-page.component';

import{AccumulationChartModule,PieSeriesService,AccumulationDataLabelService} from '@syncfusion/ej2-angular-charts';
import { ChartDirectiveDirective } from './Components/score-card/chart-directive.directive'
import { GoogleChartsModule } from 'angular-google-charts';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { CheckBoxSelectionService, FilteringEventArgs, } from '@syncfusion/ej2-angular-dropdowns';
import { enableProdMode } from '@angular/core';


enableProdMode();
enableRipple(true);


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PageNotFoundComponent,
    AccountComponent,
    DashboardComponent,
    ScoreCardComponent,
    FinalRemark,
    SupportComponent,
    UserServicesComponent,
    LoadingPageComponent,
    ChartDirectiveDirective,
    
    
  ],
  imports: [
    GoogleChartsModule,
    BrowserModule,
    RichTextEditorModule,
    AppRoutingModule,
    FormsModule,
    ButtonModule,
    SidebarModule,
    ListViewAllModule,
    SidebarModule,
    MenuAllModule,
    TreeViewAllModule,
    RadioButtonModule,
    ButtonModule,
    MatCardModule,
    MatToolbarModule,
    NgMatSearchBarModule,
    GridModule,
    BrowserAnimationsModule,
    MatIconModule,
    DropDownListModule,
    DatePickerModule,
    HttpClientModule,
    MatDialogModule,
    ChartModule,
    CommonModule,
    NgbModule,
    AccumulationChartModule,
    MatCheckboxModule,
    CheckBoxModule,
    MultiSelectModule,
    
    
    ToastrModule.forRoot({
      progressBar: true,
      timeOut: 2000,
      newestOnTop: true,
      preventDuplicates: true,
    })
  ],
  entryComponents: [
    ChartModule
  ],
  exports: [
    ChartModule
  ],
  providers: [PageService, ApiService, CategoryService, DateTimeService, ScrollBarService, ColumnSeriesService, LineSeriesService,
    ChartAnnotationService, RangeColumnSeriesService, StackingColumnSeriesService,
    LegendService, TooltipService, DataLabelService,PieSeriesService,AccumulationDataLabelService,SortService,FilterService,GroupService,DatePipe,CheckBoxSelectionService,],
  bootstrap: [AppComponent]
})
export class AppModule { }
