<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" id="staticBackdropLabel">{{rem}}</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="modalField" *ngIf="view==1">
                    <p>Download package Body</p>
                </div>
                <div class="modalField" *ngIf="view==2">

                    <label for="upload1" class="label upload1"></label>
                    <input type="file" accept=".zip" (change)="uploadFile($event)" placeholder="Browse" id="upload1"
                        hidden /><br>

                    <div id="file-chosen " class="fileName">{{fileName}} &nbsp;&nbsp;&nbsp;&nbsp; <label for="fileMb"
                            aria-hidden="true">{{fileMb}} Mb</label></div>
                    <!-- <label id="file"></label> -->
                    <div class="progress" *ngIf="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                            [style.width]="progress + '%'">
                            {{progress}}%</div>
                    </div>
                    <br>
                    <p id="status"></p>
                </div>
                <div class="modalField" *ngIf="view==3">

                </div>

            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="view==1" class="btn btn-primary  btn-sm">Download</button>
                <button type="button" *ngIf="view==2" class="btn btn-primary btn-sm uploadBtn" [disabled]="clicked"
                    (click)="inputFileUpload();clicked = true;"> Upload
                </button>

                <button type="button" *ngIf="view==3" class="btn btn-primary  btn-sm">
                    Cuc Upload </button>

                <!-- <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">No</button> -->
            </div>
        </div>
    </div>
</div>
<!-- <ejs-richtexteditor id='defaultRTE'  [toolbarSettings]='tools'></ejs-richtexteditor> -->
<div class="container  ">
    <div class="row col-12">
        <form class="searchform d-flex  justify-content-center">
            <div class="input-group mb-3 ">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="searchIcon"><i class="fas fa-search"></i></span>
                </div>
                <input class="form-control me-2 searchBar " type="search" id="search" placeholder="Search"
                    aria-label="Search" (keyup)="onKey($event)">
            </div>
            <button class="btn btn1" type="submit" (click)="searchData()" [disabled]="click">Search</button>
        </form>

            <!-- <div class="col-2 toolbar d-flex  justify-content-end">
                <ejs-richtexteditor id='defaultRTE' [toolbarSettings]='tools'></ejs-richtexteditor>
            </div> -->
       
    </div>
</div>
<div class="div">
    <div class="text-center" [ngClass]="(isLoaded==false) ? 'showField' : 'hideField'">
        <img src="../../../assets/images/please-wait.gif" class="rounded mx-auto d-block mt-2 mb-3">
    </div>
    <div class="gridData" [ngClass]="(isLoaded==true) ? 'showField' : 'hideField'">
        <!-- style="overflow-x: auto;" -->
        <ejs-grid [dataSource]='data' allowTextWrap='true'>
            <e-columns>
                <e-column field='jobId' headerText='Job Id' textAlign='center'></e-column>
                <e-column field='articleId' headerText='Article Id'></e-column>
                <e-column field='packageName' headerText='Package Name' textAlign='center' format='C2'>
                </e-column>
                <!-- <e-column field='jsonName' headerText='Json Name' textAlign='center' format='yMd' width='50'>
                </e-column> -->
                <e-column field='status' headerText='Status' textAlign='center' format='yMd'></e-column>
                <e-column field='inputFile' headerText='Input Package' textAlign='center' format='yMd'>
                    <ng-template #template let-data1>
                        <button type="button" class="btn  response-submit-btn btn-sm" data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop" (click)="confirmMsg('Download Package',1)"
                            [ngClass]="(StatusId == 8||StatusId == 1||StatusId == 2|| StatusId == 4 || StatusId == 5||StatusId == 3|| StatusId == 6|| StatusId == 7) ? 'disabled' : 'enabled' ">
                            <i class="fa fa-download">
                                {{Msg=="Download"? "Download" :"Downloading" }}
                            </i>
                        </button>
                    </ng-template>
                </e-column>
                <e-column field='packageUpload' headerText='To eAssistant' textAlign='center' format='yMd'>
                    <ng-template #template let-data1>
                        <button type="button" class="btn response-submit-btn btn-sm" data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop" (click)="confirmMsg('Upload Package',2)"
                            [ngClass]="(StatusId == 1||StatusId==8||StatusId == 0|| StatusId == 4 || StatusId == 5||StatusId == 3||StatusId == 7|| StatusId == 6) ? 'disabled' : 'enabled' ">
                            <i class="fas fa-upload"></i> Upload</button>
                    </ng-template>
                </e-column>
                <e-column field='cucCall' headerText='To CUC ' textAlign='center' format='yMd'>
                    <ng-template #template let-data1>
                        <button type="button" class="btn  response-submit-btn btn-sm" data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop" (click)="confirmMsg('Upload Package',3)"
                            [ngClass]="(StatusId == 2|| StatusId == 4 || StatusId == 5||StatusId == 3|| StatusId == 6) ? 'disabled' : 'enabled' ">
                            <i class="fas fa-upload"></i> Upload</button>
                    </ng-template>
                </e-column>
                <e-column field='linkSent' headerText='Link Sent' textAlign='center' format='yMd'>
                    <ng-template #template let-data1>

                        <a href={{data1?.Link}} title={{data1.Details}} target="_blank"
                            [ngClass]="(data1?.Link == '') ? 'Invalid' : 'Valid'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" style="color: blue;"
                                class="bi bi-link" viewBox="0 0 16 16"
                                [ngClass]="(StatusId == 1||StatusId == 8|| StatusId == 4 || StatusId == 5||StatusId == 7|| StatusId == 6) ? 'disabled' : ' ' ">
                                <path
                                    d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                                <path
                                    d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                            </svg></a>
                    </ng-template>
                </e-column>
                <e-column field='stsApi' headerText='API Signal' textAlign='center' format='yMd'>
                    <ng-template #template let-data1>
                        <button type="button" class="btn response-submit-btn btn-sm" data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                            [ngClass]="(StatusId == 1||StatusId==8|| StatusId == 7|| StatusId == 6) ? 'disabled' : 'enabled' ">
                            <i class="fas fa-share-square"></i> Send</button>
                    </ng-template>
                </e-column>
                <e-column field='Email' headerText='Email' textAlign='center' format='yMd'>
                    <ng-template #template let-data1>
                        <button type="button" class="btn response-submit-btn  btn-sm" data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                            [ngClass]="(StatusId == 1||StatusId==8||StatusId == 2|| StatusId == 4 || StatusId == 5||StatusId == 3||StatusId == 7) ? 'disabled' : 'enabled' ">
                            <i class="fas fa-share-square"></i> Send</button>
                    </ng-template>
                </e-column>
                <e-column field='delPackage' headerText='Deliver Package' textAlign='center' format='yMd'>
                    <ng-template #template let-data1>
                        <button type="button" class="btn response-submit-btn  btn-sm" data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                            [ngClass]="(StatusId == 1|| StatusId == 4||StatusId==8||StatusId == 2||StatusId == 7||StatusId == 3|| StatusId == 6) ? 'disabled' : 'enabled' ">
                            Deliver</button>
                    </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>
    </div>

</div>