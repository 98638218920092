
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { Grid, PageSettingsModel, Sort } from '@syncfusion/ej2-angular-grids'
import { ApiService } from '../services/api.service';
import { GenerateExcelService } from '../services/generate-excel.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";

import { ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  providers: [ToolbarService, HtmlEditorService]
})
export class SupportComponent implements OnInit {

  InPutFile!: File;
  public search = "";
  public pageNo = 1;
  public vendorId: any = "";
  public sorting = false;
  public sortingOrder = 0
  public dataLength: any;
  public sortingCol = "Id"
  public dataCount: any = 0;
  public pageCount: any = 0;
  public data!: any[];
  public pageNoEle: any;
  public state: any = "Download";
  public rem: any;
  public view: number = 0;
  UploadMessage: boolean = false;
  public StatusId = 0;
  public fileMb: any = 0;
  public progress!: number;
  public isLoaded: boolean = false;
  public fileName:any;
  click: boolean = true;
  public clicked = false;
  Msg: String = "Download"
  public tools: object = {
    items: ['Bold', 'Italic', 'Underline', '|', 'SubScript', 'SuperScript']
  }
  onKey(event: KeyboardEvent) {
    // if value is not empty the set click to false otherwise true
    this.click = (event.target as HTMLInputElement).value === '' ? true : false;
  }
  constructor(public apiService: ApiService, private http: HttpClient) { }

  ngOnInit(): void { }

  public confirmMsg(task: string, viewNumber: number) {
    this.rem = task
    this.view = viewNumber
  }

  fileToUpload: any;

  uploadFile(event: any) {
    const uploadBtn = document.getElementById('upload1');

     let fileName = document.getElementById('file-chosen');

    //  uploadBtn.addEventListener('change', function () { fileChosen.textContent = this.files[0].name })

    // debugger
    // console.log(event);
    console.log(event.target.files[0].name);
    this.fileName=event.target.files[0].name;
     console.log(event.target.files[0]);
    this.InPutFile = event.target.files[0];
    this.fileToUpload = event
     console.log(event.target.files[0].size);
    this.fileMb = Math.round(event.target.files[0].size /1048576)
    // console.log(this.fileMb);
    // console.log(event);

    // this.inputFileUpload();

  }

  

  public searchData() {
    // confirm('Display the user details.')
    this.click = !this.click;
    let query = document.getElementById("search") as HTMLInputElement;
    this.search = query.value;
    this.asyncCall(this.pageNo, this.search);

    this.pageNoEle = document.getElementById('pageNo') as HTMLInputElement;
    // query?.addEventListener('click', () => {
    //     this.pageNo = parseInt(this.pageNoEle.value);
    //     this.applyFilters(this.search, this.sortingOrder, this.sortingCol)

    // })
  }
  public inputFileUpload() {

    // this.apiService.uploadInputFile(this.InPutFile).subscribe((event: HttpEvent<any>) => {
    //   debugger
    //   switch (event.type) {
    //     case HttpEventType.Sent:
    //       console.log('Request sent!');
    //       break;
    //     case HttpEventType.ResponseHeader:
    //       console.log('Response header received!');
    //       break;
    //     case HttpEventType.DownloadProgress:
    //       const kbLoaded = Math.round(event.loaded / 1024);
    //       console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
    //       break;
    //     case HttpEventType.Response:
    //       console.log('😺 Done!', event.body);
    //   }

    // });
    if (this.InPutFile.type == "application/x-zip-compressed") {
      console.log(this.fileToUpload)
      this.apiService.uploadInputFile(this.InPutFile).subscribe((event: HttpEvent<any>) => {
        console.log(event);
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request sent!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            // debugger
            // const kbLoaded = Math.round(event.loaded / 1024);
            this.progress = Math.round(event.loaded);
            console.log(`Uploaded! ${this.progress}%`);
            // console.log(`Download in progress! ${ kbLoaded }Kb loaded`);

            break;
          case HttpEventType.Response:
            this.progress = 100;
            console.log('😺 Done!', event.body);
            (<HTMLInputElement>document.getElementById('status')).innerText = "File uploaded succesfully";
            document.getElementById('status')!.style.color = "green";
        }
      }, e => {
        (<HTMLInputElement>document.getElementById('status')).innerText = "File not uploaded succesfully";
        document.getElementById('status')!.style.color = "red";
        console.log(e.errorMessage)
      }
      )
    }
    else {

      alert("Please select only zip file.")

    }

  }

  public async asyncCall(pageNum: any, search: any) {
    const result = await this.storeData(pageNum, search);
  }

  public storeData(pageNum: any, search: any) {
    return new Promise(resolve => {
      return this.getData(pageNum, search, this.vendorId)
    });
  }

  public getData(pageNum: any, search: any, clientId: any) {
    var tableData: any = [];
    // let pageSize = document.getElementById("pageSize") as HTMLInputElement
    this.apiService.getData(pageNum, search, clientId, 0, 'Id', 10).subscribe(
      apiData => {

        let index = 0;
        let cols = document.getElementsByClassName('e-headercell')

        let colNames = ['Id', 'FirstName', 'ArticleId', 'JournalAbbrevation', 'EditorialReferenceCode',
          'InputFileName', 'ZipFileName', 'TPReferenceNumber', 'JobId', 'Status', 'CreatedOn',
          'ModifiedOn', 'Age'];

        console.log("************columns", cols);

        console.log("***************************************APIData", apiData);
        this.pageCount = apiData.Pagination.totalPages;
        this.dataCount = apiData.Pagination.totalCount;

        sessionStorage.setItem('fromData', "");
        sessionStorage.setItem('toDate', "");
        this.StatusId = apiData.Report[0].StatusId
        // console.log(this.StatusId)


        for (let i in apiData.Report) {
          // // // console.log("***************************************Link",apiData.Report[i].Link)
          if (!(apiData.Report[i].Link)) {

          }
          tableData.push(
            {
              jobId: apiData.Report[i].JobId,
              articleId: apiData.Report[i].ArticleId,
              packageName: apiData.Report[i].PackageName,
              jsonName: apiData.Report[i].JsonName,
              status: apiData.Report[i].Status,
              inputFile: apiData.Report[i].InputFileName,
              packageUpload: apiData.Report[i].ZipFileName,
              cucCall: apiData.Report[i].CucCall,
              linkSent: apiData.Report[i].Link,
              stsApi: apiData.Report[i].StsApi,
              delPackage: apiData.Report[i].DelPackage,
              // ModifiedOn: apiData.Report[i].ModifiedOn,
              // Age: apiData.Report[i].Age,
              // ManuScriptError: apiData.Report[i].ManuScriptError,
              // Link: apiData.Report[i].Link,
              // Details: 'Details'
            }
          )
        }
        this.data = tableData;
        this.dataLength = this.data.length;
        this.isLoaded = true;
        // console.log("table data get data******************************************",this.data)

      }
    )
    // debugger
    // // // // console.log(tableData)
    return tableData;
  }
}
function finalize(arg0: () => void): import("rxjs").OperatorFunction<import("@angular/common/http").HttpEvent<Object>, unknown> {
  throw new Error('Function not implemented.');
}

