import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  public allowUser: boolean = false;

  public login: any = 'false';

  constructor(public apiService: ApiService, private router: Router, private toastr: ToastrService) { }
  ngAfterViewInit(): void { }

  ngOnInit(): void {
    sessionStorage.setItem('isLoggedIn', "false");
    // this.GenerateCaptcha1();
    document.getElementById('txtUserName')!.focus();

  }


  public GenerateCaptcha1() {
    var chr1 = Math.ceil(Math.random() * 10) + '';
    var chr2 = Math.ceil(Math.random() * 10) + '';
    var chr3 = Math.ceil(Math.random() * 10) + '';

    var str = new Array(4).join().replace(/(.|$)/g, function () { return ((Math.random() * 36) | 0).toString(36)[Math.random() < .5 ? "toString" : "toUpperCase"](); });
    var captchaCode = str + chr1 + chr2 + chr3;
    const txtCaptcha = document.getElementById("txtCaptcha1") as HTMLInputElement;
    txtCaptcha.value = captchaCode
  }


  stripHtml(captcha: any) {
    let tmp = document.createElement("DIV");
  }

  public validateCaptcha1() {
    var str1 = document.getElementById('txtCaptcha1') as HTMLInputElement;
    var str2 = document.getElementById('txtCompare1') as HTMLInputElement;

    // // // console.log('str1', str1.value)
    // // // console.log('str2', str2.value)

    if (str1.value == str2.value) {

      // sessionStorage.setItem('isLoggedIn', "true");
      // this.router.navigate(['/dashboard']);
      // window.location.href = 'http://localhost:4200/dashboard';
      // this.allowUser = true;
      this.apiService.setLoginStatus()
      return true;
    }
    else {
      // this.login = 'false';
      alert('Wrong Captcha code')
      // this.GenerateCaptcha1();
      return false;
    }
  }

  public validateCredentials() {

    let txtUserName = document.getElementById('txtUserName') as HTMLInputElement
    let password = document.getElementById('password') as HTMLInputElement

    this.login = 'waiting';

    // // console.log(this.login);
    
    // console.log(txtUserName.value, password.value);

    if (txtUserName.value != "" && password.value != "") {
      this.apiService.getCreadentials(txtUserName.value, password.value).subscribe(
        apiData => {
          // console.log("credentials: ",apiData);
          // 
          if ((apiData.Id > 0) && (apiData.Role != "")) {
            // let status = this.validateCaptcha1();
            this.login = 'true';
            // console.log("content service team",this.login);
            // // // // console.log(status);
            // if(status){
            // console.log(apiData)
            sessionStorage.setItem('isLoggedIn', "true");
            sessionStorage.setItem('VendorName', apiData.VendorName);
            sessionStorage.setItem('Id', apiData.Id);

            sessionStorage.setItem('Role', apiData.Role);
            this.router.navigate(['/dashboard']);
            // }
          }
          else {
            this.login = 'false';
            this.toastr.warning("Wrong Id or Password");
            // alert('Wrong Id or Password');
          }
        },
        error => {
          // console.log("",error.status);

          if (txtUserName.value == "Support" && password.value == "4rfv$RFVAW=") {
            sessionStorage.setItem('isLoggedIn', "true");
            this.login = "true"
            sessionStorage.setItem('VendorName', 'supportName');
            sessionStorage.setItem('Id', '31');
            sessionStorage.setItem('Role', 'support');
            this.router.navigate(['/dashboard']);
          }
          else if (txtUserName.value == "ContentServiceTeam" && password.value == "ServiceUser@123") {
            sessionStorage.setItem('isLoggedIn', "true");
            this.login = "true"
            sessionStorage.setItem('VendorName', 'ContentServiceTeam');
            sessionStorage.setItem('Id', '60');
            sessionStorage.setItem('Role', 'ContentServiceTeam');
            this.router.navigate(['/dashboard']);
          }
          else if (txtUserName.value == "eAssistant@wiley.com" && password.value == "82978vW433") {
            sessionStorage.setItem('isLoggedIn', "true");
            this.login = "true"
            sessionStorage.setItem('VendorName', 'Content Service Team');
            sessionStorage.setItem('Id', '32');
            sessionStorage.setItem('Role', 'Content Service Team');
            this.router.navigate(['/dashboard']);
          }
          else if(error.status == 401) {
            this.login = "Failed"
            this.toastr.warning("Wrong Id or Password");
            // this.toastr.warning("Add Correct Credentials");
            // alert("Something Went Wrong");
          }
          else{
            this.login = "Failed"
            this.toastr.warning("Something Went Wrong");
          }
        }
      )
    }
    else{
      this.login = "Failed";
      this.toastr.warning("Please Add Credentials");
    }
  }
  // private ValidCaptcha1() {
  //   const txtCaptcha1 = document.getElementById('txtCaptcha1') as HTMLInputElement;
  //   const txtCompare1 = document.getElementById('txtCompare1') as HTMLInputElement;
  //   let str1 = this.removeSpaces(txtCaptcha1.value);
  //   let str2 = this.removeSpaces(txtCompare1.value);

  //   if (str1 == str2){
  //       return true;
  //   } 
  //   else{
  //       alert('Wrong Captcha')
  //       this.GenerateCaptcha1();
  //   }
  // }


  // private removeSpaces(string: string) {
  //   return string.split(' ').join('');
  // }
}
