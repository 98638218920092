import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-score-card',
  templateUrl: './score-card.component.html',
  styleUrls: ['./score-card.component.scss']
})
export class ScoreCardComponent implements OnInit {
  
  public chartTitle!: string
  public chartLable!: object
  public primaryXAxis!: Object;
  public primaryYAxis!: Object;
  public chartData: Object[] = [
    { type: 'Article Title', value: 0 },
    { type: 'Author Group', value: 0 },
    { type: 'Affiliation', value: 0 },
    { type: 'Corresponding Author', value: 0 },
    { type: 'Corresponding Author Email', value: 0 },
    { type: 'Abstract', value: 0 },
    { type: 'Keyword', value: 0 },
    { type: 'Figure', value: 0 },
    { type: 'Table', value: 0 },
    { type: 'Reference', value: 0 },
  ]; PieChartData: any;
  
  
  public chartData2: Object[] = [
    { type: 'Article Title', value: 0 },
    { type: 'Author Group', value: 0 },
    { type: 'Affiliation', value: 0 },
    { type: 'Corresponding Author', value: 0 },
    { type: 'Corresponding Author Email', value: 0 },
    { type: 'Abstract', value: 0 },
    { type: 'Keyword', value: 0 },
    { type: 'Figure', value: 0 },
    { type: 'Table', value: 0 },
    { type: 'Reference', value: 0 },
  ];;;
  public chartData3: Object[] = [
    { type: 'Article Title', value: 0 },
    { type: 'Author Group', value: 0 },
    { type: 'Affiliation', value: 0 },
    { type: 'Corresponding Author', value: 0 },
    { type: 'Corresponding Author Email', value: 0 },
    { type: 'Abstract', value: 0 },
    { type: 'Keyword', value: 0 },
    { type: 'Figure', value: 0 },
    { type: 'Table', value: 0 },
    { type: 'Reference', value: 0 },
  ];;;
  public marker!: Object;
  public palette!: string[];
  public legendSettings!: Object;
  // public fromDate!: any;
  // public toDate!: any;
  // public vendorId!: any;
  // public journalAbrv!: any;
  // public vendorRole!: any;
  public apiData: any = [{
    Precision: 0,
    Recall: 0,
    Accuracy: 0
  }];

    // Gauge chart START
    ngAfterContentChecked() {
      this.gauge_ChartData
    }

    public gauge_ChartData = [
      ['Label', 'Value'],
          ['Precision', 0],
          ['Recall', 0],
          ['Accurracy', 0]
    ];
    public gauge_ChartOptions = {
      width: 480, height: 220,
      greenFrom: 90, greenTo: 100,
      minorTicks: 5,
    };
    
    // Gauge chart END


  @Input() fromDate: any;
  @Input() toDate: any;
  @Input() vendorId: any;
  @Input() journalAbrv: any;
  @Input() vendorName: any;
  @Input() vendorRole: any;



  constructor(public apiService: ApiService) { }

  ngOnInit(): void {

    // let fromDate = localStorage.getItem('fromData');
    // let toDate = localStorage.getItem('toDate');
    // let vendorId = localStorage.getItem('Id');
    // let journalAbrv = localStorage.getItem('journalAbrv')
    // let vendorRole = localStorage.getItem('vendorRole')
    // let clientName = localStorage.getItem('clientName')

    // this.fromDate = localStorage.getItem('fromData');
    // this.toDate = localStorage.getItem('toDate');
    // this.vendorId = localStorage.getItem('Id');
    // this.journalAbrv = localStorage.getItem('journalAbrv');
    // this.vendorRole = localStorage.getItem('vendorRole');

    if (this.fromDate == null) {
      this.fromDate = ""
    }
    if (this.toDate == null) {
      this.toDate = ""
    }
    if (this.journalAbrv == null) {
      this.journalAbrv = ""
    }

    if (this.vendorRole == "Admin") {
      this.vendorName = "All users"
    }

    if (this.vendorName == "EASSISTANT@WILEY.COM") {
      this.vendorName = "Content Service Team"
    }

    console.log(this.fromDate + " " + this.toDate + " " + this.vendorId + " " + this.journalAbrv + " " + this.vendorName + " done")
    this.apiService.getTotalData(this.vendorId, this.journalAbrv, this.fromDate, this.toDate).subscribe(
      apiData => {
        // debugger
        // console.log("chart data ********************************************", apiData);

        this.apiData = apiData;
        
          
        
        // this.chartData = [
        //   { type: 'Article Title', value: apiData.ArticleTitleRecall },
        //   { type: 'Author Group', value: apiData.AuthorGroupRecall },
        //   { type: 'Affiliation', value: apiData.AffiliationRecall },
        //   { type: 'Corresponding Author', value: apiData.Corresponding_AuthorRecall },
        //   { type: 'Corresponding Author Email', value: apiData.Corresponding_Author_EmailRecall },
        //   { type: 'Abstract', value: apiData.AbstractRecall },
        //   { type: 'Keyword', value: apiData.KeywordRecall },
        //   { type: 'Figure', value: apiData.FigureRecall },
        //   { type: 'Table', value: apiData.TableRecall },
        //   { type: 'Reference', value: apiData.ReferenceRecall },
        // ];

        // this.chartData2 = [
        //   { type: 'Article Title', value: apiData.ArticleTitlePrecision },
        //   { type: 'Author Group', value: apiData.AuthorGroupPrecision },
        //   { type: 'Affiliation', value: apiData.AffiliationPrecision },
        //   { type: 'Corresponding Author', value: apiData.Corresponding_AuthorPrecision },
        //   { type: 'Corresponding Author Email', value: apiData.Corresponding_Author_EmailPrecision },
        //   { type: 'Abstract', value: apiData.AbstractPrecision },
        //   { type: 'Keyword', value: apiData.KeywordPrecision },
        //   { type: 'Figure', value: apiData.FigurePrecision },
        //   { type: 'Table', value: apiData.TablePrecision },
        //   { type: 'Reference', value: apiData.ReferencePrecision },
        // ];

        this.chartData = [
          { type: 'Article Title', value: apiData[3].Recall },
          { type: 'Author Group', value: apiData[4].Recall },
          { type: 'Affiliation', value: apiData[2].Recall },
          { type: 'Corresponding Author', value: apiData[5].Recall },
          { type: 'Corresponding Author Email', value: apiData[6].Recall },
          { type: 'Abstract', value: apiData[1].Recall },
          { type: 'Keyword', value: apiData[9].Recall },
          { type: 'Figure', value: apiData[8].Recall },
          { type: 'Table', value: apiData[11].Recall },
          { type: 'Reference', value: apiData[10].Recall },
        ];
      
        this.chartData2 = [
          { type: 'Article Title', value: apiData[3].Precision },
          { type: 'Author Group', value: apiData[4].Precision },
          { type: 'Affiliation', value: apiData[2].Precision },
          { type: 'Corresponding Author', value: apiData[5].Precision },
          { type: 'Corresponding Author Email', value: apiData[6].Precision },
          { type: 'Abstract', value: apiData[1].Precision },
          { type: 'Keyword', value: apiData[9].Precision },
          { type: 'Figure', value: apiData[8].Precision },
          { type: 'Table', value: apiData[11].Precision },
          { type: 'Reference', value: apiData[10].Precision },
        ];

        this.chartData3 = [
          { type: 'Article Title', value: apiData[3].Accuracy },
          { type: 'Author Group', value: apiData[4].Accuracy },
          { type: 'Affiliation', value: apiData[2].Accuracy },
          { type: 'Corresponding Author', value: apiData[5].Accuracy },
          { type: 'Corresponding Author Email', value: apiData[6].Accuracy },
          { type: 'Abstract', value: apiData[1].Accuracy },
          { type: 'Keyword', value: apiData[9].Accuracy },
          { type: 'Figure', value: apiData[8].Accuracy },
          { type: 'Table', value: apiData[11].Accuracy },
          { type: 'Reference', value: apiData[10].Accuracy },
        ];

        // let maxRange = Math.max(apiData.ArticleTitleRecall, apiData.AuthorGroupRecall, apiData.AffiliationRecall, apiData.Corresponding_AuthorRecall,
        //   apiData.Corresponding_Author_EmailRecall, apiData.AbstractRecall, apiData.KeywordRecall, apiData.FigureRecall, apiData.TableRecall, apiData.ReferenceRecall,
        //   apiData.ArticleTitlePrecision, apiData.AuthorGroupPrecision, apiData.AffiliationPrecision, apiData.Corresponding_AuthorPrecision,
        //   apiData.Corresponding_Author_EmailPrecision, apiData.AbstractPrecision, apiData.KeywordPrecision, apiData.FigurePrecision,
        //   apiData.TablePrecision, apiData.ReferencePrecision) + 0.1

        this.primaryXAxis = {
          valueType: 'Category',
          title: 'Article Elements'
        };

        this.primaryYAxis = {
          minimum: 0, maximum: 100,
          interval: 20,
          labelFormat: '{value}%',
          title: 'Average in %',
          // titleStyle: {
          //   size: '16px', color: 'grey',
          //   fontFamily: 'Segoe UI', fontWeight: 'bold'
          // }
        }

        this.marker = { dataLabel: { visible: true } }

        this.palette = ["#006CB1", "#22a5e6", "#ADD8E6"];

        this.legendSettings = {
          visible: true,
          position: 'Top',
          // position: 'Custom',
          location: { x: 200, y: 40 }
        };
      }
    )
  }

  ngOnChanges(): void {

    if (this.fromDate == null) {
      this.fromDate = ""
    }
    if (this.toDate == null) {
      this.toDate = ""
    }
    if (this.journalAbrv == null) {
      this.journalAbrv = ""
    }

    if (this.vendorRole == "Admin") {
      this.vendorName = "All users"
    }

    if (this.vendorName == "EASSISTANT@WILEY.COM") {
      this.vendorName = "Content Service Team"
    }
    console.log(this.fromDate + " " + this.toDate + " " + this.vendorId + " " + this.journalAbrv + " " + this.vendorName + " done")
    this.apiService.getTotalData(this.vendorId, this.journalAbrv, this.fromDate, this.toDate).subscribe(
      apiData => {
        // debugger
        // console.log("chart data1 ********************************************", apiData);
        
        
        this.gauge_ChartData = [
          ['Label', 'Value'],
          ['Precision', parseInt(apiData[0].Precision)],
          ['Recall', parseInt(apiData[0].Recall)],
          ['Accurracy', parseInt(apiData[0].Accuracy)]
        ];
        
        this.apiData = apiData;

        // this.chartData = [
        //   { type: 'Article Title', value: apiData.ArticleTitleRecall },
        //   { type: 'Author Group', value: apiData.AuthorGroupRecall },
        //   { type: 'Affiliation', value: apiData.AffiliationRecall },
        //   { type: 'Corresponding Author', value: apiData.Corresponding_AuthorRecall },
        //   { type: 'Corresponding Author Email', value: apiData.Corresponding_Author_EmailRecall },
        //   { type: 'Abstract', value: apiData.AbstractRecall },
        //   { type: 'Keyword', value: apiData.KeywordRecall },
        //   { type: 'Figure', value: apiData.FigureRecall },
        //   { type: 'Table', value: apiData.TableRecall },
        //   { type: 'Reference', value: apiData.ReferenceRecall },
        // ];

        // this.chartData2 = [
        //   { type: 'Article Title', value: apiData.ArticleTitlePrecision },
        //   { type: 'Author Group', value: apiData.AuthorGroupPrecision },
        //   { type: 'Affiliation', value: apiData.AffiliationPrecision },
        //   { type: 'Corresponding Author', value: apiData.Corresponding_AuthorPrecision },
        //   { type: 'Corresponding Author Email', value: apiData.Corresponding_Author_EmailPrecision },
        //   { type: 'Abstract', value: apiData.AbstractPrecision },
        //   { type: 'Keyword', value: apiData.KeywordPrecision },
        //   { type: 'Figure', value: apiData.FigurePrecision },
        //   { type: 'Table', value: apiData.TablePrecision },
        //   { type: 'Reference', value: apiData.ReferencePrecision },
        // ];
       
        this.chartData = [
          { type: 'Article Title', value: apiData[3].Recall },
          { type: 'Author Group', value: apiData[4].Recall },
          { type: 'Affiliation', value: apiData[2].Recall },
          { type: 'Corresponding Author', value: apiData[5].Recall },
          { type: 'Corresponding Author Email', value: apiData[6].Recall },
          { type: 'Abstract', value: apiData[1].Recall },
          { type: 'Keyword', value: apiData[9].Recall },
          { type: 'Figure', value: apiData[8].Recall },
          { type: 'Table', value: apiData[11].Recall },
          { type: 'Reference', value: apiData[10].Recall },
        ];
      
       
        this.chartData2 = [
          { type: 'Article Title', value: apiData[3].Precision },
          { type: 'Author Group', value: apiData[4].Precision },
          { type: 'Affiliation', value: apiData[2].Precision },
          { type: 'Corresponding Author', value: apiData[5].Precision },
          { type: 'Corresponding Author Email', value: apiData[6].Precision },
          { type: 'Abstract', value: apiData[1].Precision },
          { type: 'Keyword', value: apiData[9].Precision },
          { type: 'Figure', value: apiData[8].Precision },
          { type: 'Table', value: apiData[11].Precision },
          { type: 'Reference', value: apiData[10].Precision },
        ];

        this.chartData3 = [
          { type: 'Article Title', value: apiData[3].Accuracy },
          { type: 'Author Group', value: apiData[4].Accuracy },
          { type: 'Affiliation', value: apiData[2].Accuracy },
          { type: 'Corresponding Author', value: apiData[5].Accuracy },
          { type: 'Corresponding Author Email', value: apiData[6].Accuracy },
          { type: 'Abstract', value: apiData[1].Accuracy },
          { type: 'Keyword', value: apiData[9].Accuracy },
          { type: 'Figure', value: apiData[8].Accuracy },
          { type: 'Table', value: apiData[11].Accuracy },
          { type: 'Reference', value: apiData[10].Accuracy },
        ];

        // let maxRange = Math.max(apiData.ArticleTitleRecall, apiData.AuthorGroupRecall, apiData.AffiliationRecall, apiData.Corresponding_AuthorRecall,
        //   apiData.Corresponding_Author_EmailRecall, apiData.AbstractRecall, apiData.KeywordRecall, apiData.FigureRecall, apiData.TableRecall, apiData.ReferenceRecall,
        //   apiData.ArticleTitlePrecision, apiData.AuthorGroupPrecision, apiData.AffiliationPrecision, apiData.Corresponding_AuthorPrecision,
        //   apiData.Corresponding_Author_EmailPrecision, apiData.AbstractPrecision, apiData.KeywordPrecision, apiData.FigurePrecision,
        //   apiData.TablePrecision, apiData.ReferencePrecision) + 0.1

        this.primaryXAxis = {
          valueType: 'Category',
          title: 'Article Elements'
        };

        this.primaryYAxis = {
          minimum: 0, maximum: 100,
          interval: 20,
          labelFormat: '{value}%',
          title: 'Average in %',
          // titleStyle: {
          //   size: '16px', color: 'grey',
          //   fontFamily: 'Segoe UI', fontWeight: 'bold'
          // }
        }
        
        this.marker = { dataLabel: { visible: true } }

        this.palette = ["#006CB1", "#22a5e6", "#ADD8E6"];

        this.legendSettings = {
          visible: true,
          position: 'Top',
          // position: 'Custom',
          location: { x: 200, y: 40 }
        };
      }

    )

  }

  public closeScoreCard() {
    window.close();
  }

}
