<div class="w-100 d-flex justify-content-center">
    <div class="modal custom-modal fade m-4 ml-5" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
        tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title" id="staticBackdropLabel">Upload Packages</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <input type="file" accept=".zip,.rar,.7zip" />
                    <br>
                    <br>
                    <div class="progress">
                        <div class="progress-bar bg-info" role="progressbar" aria-valuenow="" aria-valuemin="0"
                            aria-valuemax="100" id="progressBar"></div>
                    </div>

                    <!-- <div class="progress-bar-container " >
                        <div class="progress-bar {{color}}" 
                         [ngStyle]="{'width': progress + '%'}">
                        </div>
                     </div> -->


                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-primary">Ok</button> -->
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Ok</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade modal-xl" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header text-center">
                    <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
                    <h1 class="modal-title w-100">Score Card</h1>

                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body custom-modal-body">
                    <app-score-card [vendorId]="filterVendorId" [vendorRole]="filterVendorRole"
                        [vendorName]="filterVendorName" [fromDate]="fromDate" [toDate]="toDate"
                        [journalAbrv]="journalAbrv"></app-score-card>

                    <app-user-services>
                    </app-user-services>
                </div>
                <!-- <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div> -->
            </div>
        </div>
    </div>
</div>
<!-- <div class="modal fade" id="alertDates" role="dialog">
    <div class="modal-dialog">
    
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">Modal Header</h4>
        </div>
        <div class="modal-body">
          <p>Please select atmost one month range</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>
      
    </div>ks
  </div> -->
<div id="mainBody" style="overflow-y: hidden; overflow-x:hidden">
    <!-- <mat-toolbar id="matNavBar">
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon>menu</mat-icon>
    </button>
    <span id="matNavBarHeading">eAssistant Report</span>
    <span class="example-spacer"></span>
    <mat-search-bar style="border-color:black"></mat-search-bar>
    <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
      <mat-icon>favorite</mat-icon>
    </button>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
      <mat-icon>share</mat-icon>
    </button>
    <form class="d-flex pull-right">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success" type="submit">Search</button>
    </form>
    </mat-toolbar> -->

    <mat-toolbar style="background-color:#005274 !important;">
        <!-- <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon>menu</mat-icon>
    </button> -->
        <!-- <span id="matNavBarHeading">eAssistant Report</span> -->
        <!-- <span class="header">eAssistant Report</span> -->
        <img src="assets/images/wiley_logo_tranparent.png" class="rounded mx-auto d-block mt-2 mb-3"
            style="width: 7rem">&nbsp;&nbsp;
        <a class="btn btn-success me-2 " *ngIf="vendorId=='31'" type="btn" [routerLink]="['/support']">Support Page</a>
        <!-- <div *ngIf="supportImg" class="d-flex justify-content-center"><img src="../../../assets/images/loading.gif" class="img-thumbnail" alt="..."></div> -->

        <span class=""
            [ngClass]="(vendorRole == 'Admin') ? 'example-spacer-center-admin' : 'example-spacer-center-vendor'"></span>
        <img src="assets/images/eAssistant Portal Logo White.png" class="rounded mx-auto d-block mt-2 mb-3"
            style="width: 13rem">

        <span class="example-spacer-end"></span>

        <!-- <button class="btn btn-secondary me-2" type="btn" (click)="openScoreCard()">Score Popup</button> -->
        <!-- <iframe [src]="http://localhost:4200/scorecard" width="700" height="800" >Score Popup</iframe> -->
        <!-- <a class="btn btn-success me-2" type="btn" href="/scorecard" target="_blank">Score Card</a> -->

        <button type="button" id="Scorecard-btn" class="btn btn-success me-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
            Scorecard
        </button>
        <a class="btn btn-success me-2 " *ngIf="vendorId=='60'" type="btn" data-bs-toggle="modal"
            data-bs-target="#staticBackdrop">Upload Package</a>


        <form class="d-flex pull-right">
            <input class="form-control me-2" type="search" id="search" placeholder="Search" aria-label="Search">

            <button class="btn btn-success me-2" id="SearchAllData" type="submit" (click)="searchData()">Search</button>
        </form>
        <mat-icon style="color:white" class="me-2">account_circle</mat-icon>
        <span style="color:white; font-size: 1rem; cursor: pointer;" class="me-1" data-bs-toggle="collapse"
            href="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            {{vendorName}}
        </span>

        <span style="color:white; font-size: 1rem; cursor: pointer;" class="me-1" data-bs-toggle="collapse"
            href="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            ({{vendorRole}})
        </span>

        <div class="collapse" id="collapseExample">
            <button class="btn btn-primary me-2" id="Logout-btn" type="submit" (click)="logout()">Logout</button>
        </div>
        <!-- <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
      <mat-icon>favorite</mat-icon>
    </button>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
      <mat-icon>share</mat-icon>
    </button> -->
        <!-- <button class="btn btn-secondary me-3" type="btn">Score Card</button>
            
        <form class="d-flex pull-right">
            <input class="form-control me-2" type="search" id="search" placeholder="Search" aria-label="Search">
            <button class="btn btn-success" type="submit" (click)="searchData()">Search</button>
        </form> -->
        <!-- <mat-search-bar style="border-color:black"></mat-search-bar> -->


        <!-- <a class="btn btn-primary" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
            aria-controls="collapseExample">
            Link with href
        </a> -->

    </mat-toolbar>

    <!-- <div class="row mt-5">
    <div class="col-3">
    </div>
    <div class="col-2">
        <mat-card class="justify-content-center">
            <div class="text-center">13341</div>
            <div class="text-center"> Manuscript Received</div>
        </mat-card>
    </div>
    <div class="col-2">
        <mat-card class="justify-content-center">
            <div class="text-center">13341</div>
            <div class="text-center"> Manuscript Received</div>
        </mat-card>
    </div>
    <div class="col-2">
        <mat-card class="justify-content-center">
            <div class="text-center">13341</div>
            <div class="text-center"> Manuscript Received</div>
        </mat-card>
    </div>
    <div class="col-3">

    </div>
</div>

<div class="row mt-3">
    <div class="col-8 row mx-auto">


        <div class="col-3">
            <mat-card class="justify-content-center">
                <div class="text-center">13341</div>
                <div class="text-center"> Manuscript Received</div>
            </mat-card>
        </div>
        <div class="col-3">
            <mat-card class="justify-content-center">
                <div class="text-center">13341</div>
                <div class="text-center"> Manuscript Received</div>
            </mat-card>
        </div>
        <div class="col-3">
            <mat-card class="justify-content-center">
                <div class="text-center">13341</div>
                <div class="text-center"> Manuscript Received</div>
            </mat-card>
        </div>
        <div class="col-3">
            <mat-card class="justify-content-center">
                <div class="text-center">13341</div>
                <div class="text-center"> Manuscript Received</div>
            </mat-card>
        </div>


    </div>
</div>


<div class="row mt-3">
    <div class="col-8 row mx-auto">


        <div class="col-3">
            <mat-card class="justify-content-center">
                <div class="text-center">13341</div>
                <div class="text-center"> Manuscript Received</div>
            </mat-card>
        </div>
        <div class="col-9 row justify-content-between">

            <div class="col-3">
                <mat-card class="justify-content-center">
                    <div class="text-center">13341</div>
                    <div class="text-center"> Manuscript Received</div>
                </mat-card>
            </div>
            <div class="col-3">
                <mat-card class="justify-content-center">
                    <div class="text-center">13341</div>
                    <div class="text-center"> Manuscript Received</div>
                </mat-card>
            </div>
            <div class="col-3">
                <mat-card class="justify-content-center">
                    <div class="text-center">13341</div>
                    <div class="text-center"> Manuscript Received</div>
                </mat-card>
            </div>
            <div class="col-3">
                <mat-card class="justify-content-center">
                    <div class="text-center">13341</div>
                    <div class="text-center"> Manuscript Received</div>
                </mat-card>
            </div>
        </div>


    </div>
</div>

<div class="row mt-3 mb-5">
    <div class="col-7 row mx-auto">


        <div class="col-3">
            <mat-card class="justify-content-center">
                <div class="text-center">13341</div>
                <div class="text-center"> Manuscript Received</div>
            </mat-card>
        </div>
        <div class="col-3">
            <mat-card class="justify-content-center">
                <div class="text-center">13341</div>
                <div class="text-center"> Manuscript Received</div>
            </mat-card>
        </div>
        <div class="col-3">
            <mat-card class="justify-content-center">
                <div class="text-center">13341</div>
                <div class="text-center"> Manuscript Received</div>
            </mat-card>
        </div>
        <div class="col-3">
            <mat-card class="justify-content-center">
                <div class="text-center">13341</div>
                <div class="text-center"> Manuscript Received</div>
            </mat-card>
        </div>


    </div>
</div> -->
    <!-- Button trigger modal -->


    <!-- Modal -->

    <div id="contentBody">
        <div class="filtersSection">
            <marquee width="100%" direction="left" height="30%" scrollamount="10" onmouseover="this.stop();"
                onmouseout="this.start();">
                <h2> * The Avg. TAT is displayed in the format of Day - Hr: Min: Sec. ** The displayed TAT is captured
                    for
                    all the articles uploaded after April 16, 2021. *** Bookin Ideal Time (Wait time) is captured after
                    2021-08-23 07:16:56.723</h2>
            </marquee>

            <div id="carouselExampleDark" class="carousel carousel-dark slide mt-5" data-bs-ride="carousel"
                data-bs-interval="false">
                <div class="carousel-indicators mt-5" style="top: 3.5rem !important">
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active"
                        aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1"
                        aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2"
                        aria-label="Slide 3"></button>
                    <!-- <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3" aria-label="Slide 4"></button> -->
                </div>
                <div class="carousel-inner ">
                    <div class="carousel-item active" data-bs-interval="10000">
                        <div class="row mb-5">
                            <div class="col-3">

                            </div>
                            <div class="col-2" [ngClass]="(vendorRole == 'Admin') ? 'showField' : 'hideField'">
                                <mat-card class="justify-content-center">
                                    <div class="text-center packageTxt">{{package1}}</div>
                                    <div class="text-center packageTxt"> Manuscript Received</div>
                                </mat-card>
                            </div>
                            <div class="col-2" [ngClass]="(vendorRole != 'Admin') ? 'mx-auto' : ''">
                                <mat-card class="justify-content-center">
                                    <div class="text-center packageTxt" *ngIf="(vendorRole == 'Admin')">{{package2}}
                                    </div>
                                    <div class="text-center packageTxt" *ngIf="(vendorRole == 'Admin')"> Manuscript
                                        Uploaded
                                        to CUC
                                    </div>
                                    <div class="text-center packageTxt" *ngIf="(vendorRole != 'Admin')">{{package1}}
                                    </div>
                                    <div class="text-center packageTxt" *ngIf="(vendorRole != 'Admin')"> Manuscript
                                        Uploaded
                                    </div>
                                </mat-card>
                            </div>
                            <div class="col-2" [ngClass]="(vendorRole == 'Admin') ? 'showField' : 'hideField'">
                                <mat-card class="justify-content-center">
                                    <div class="text-center packageTxt">{{package3}}</div>
                                    <div class="text-center packageTxt">JSON Received by eAssistant</div>
                                </mat-card>
                            </div>
                            <div class="col-3">

                            </div>
                        </div>
                    </div>


                    <div class="carousel-item" data-bs-interval="2000">
                        <div class="row mb-5">
                            <div class="col-8 row mx-auto">


                                <div class="col-3">
                                    <mat-card class="justify-content-center">
                                        <div class="text-center packageTxt">{{package4}}</div>
                                        <div class="text-center packageTxt"> Bookin Link Sent</div>
                                    </mat-card>
                                </div>
                                <div class="col-3">
                                    <mat-card class="justify-content-center">
                                        <div class="text-center packageTxt">{{package5}}</div>
                                        <div class="text-center packageTxt"> Bookin In-Process</div>
                                    </mat-card>
                                </div>
                                <div class="col-3">
                                    <mat-card class="justify-content-center">
                                        <div class="text-center packageTxt">{{package6}}</div>
                                        <div class="text-center packageTxt"> Bookin Completed</div>
                                    </mat-card>
                                </div>
                                <div class="col-3">
                                    <mat-card class="justify-content-center">
                                        <div class="text-center packageTxt">{{package7}}</div>
                                        <div class="text-center packageTxt"> Final Package in-process to Deliver</div>
                                    </mat-card>
                                </div>


                            </div>
                        </div>

                    </div>


                    <div class="carousel-item">
                        <div class="row mb-5">
                            <div class="col-8 row mx-auto">


                                <div class="col-3" [ngClass]="(vendorRole == 'Admin') ? 'showField' : 'hideField'">
                                    <mat-card class="justify-content-center">
                                        <div class="text-center packageTxt">{{package8}}</div>
                                        <div class="text-center packageTxt"> Bookin Link Not Sent</div>
                                    </mat-card>
                                </div>
                                <div class="col-9 row justify-content-between">

                                    <div class="col-2" [ngClass]="(vendorRole == 'Admin') ? 'showField' : 'hideField'">
                                        <mat-card class="justify-content-center">
                                            <div class="text-center packageTxt">{{package9}}</div>
                                            <div class="text-center packageTxt"> CUC Failed</div>
                                        </mat-card>
                                    </div>
                                    <div class="col-4" [ngClass]="(vendorRole != 'Admin') ? 'mx-auto' : ''">
                                        <mat-card class="justify-content-center">
                                            <div class="text-center packageTxt">{{package10}}</div>
                                            <div class="text-center packageTxt"> Invalid Type/Metadata Missing</div>
                                        </mat-card>
                                    </div>
                                    <div class="col-3" [ngClass]="(vendorRole == 'Admin') ? 'showField' : 'hideField'">
                                        <mat-card class="justify-content-center">
                                            <div class="text-center packageTxt">{{package11}}</div>
                                            <div class="text-center packageTxt"> JSON Awaited</div>
                                        </mat-card>
                                    </div>
                                    <div class="mx-auto"
                                        [ngClass]="(vendorRole != 'Admin') ? 'col-4 mx-auto' : 'col-3'">
                                        <mat-card class="justify-content-center">
                                            <div class="text-center packageTxt">{{package12}}</div>
                                            <div class="text-center packageTxt"> Link Failure</div>
                                        </mat-card>
                                    </div>
                                    <!-- <div class="col-3">
                            <mat-card class="justify-content-center">
                                <div class="text-center">13341</div>
                                <div class="text-center"> Link Failure</div>
                            </mat-card>
                        </div> -->
                                </div>


                            </div>
                        </div>


                    </div>


                    <!-- <div class="carousel-item">
            <div class="row mb-5">
                <div class="col-7 row mx-auto">

                    <div class="col-3 mx-auto">
                        <mat-card class="justify-content-center">
                            <div class="text-center">13341</div>
                            <div class="text-center"> Link Failure</div>
                        </mat-card>
                    </div>


                </div>
            </div>
        </div> -->
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>

            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-2" style="width: 19.666667% !important">
                        <mat-card class="justify-content-center">
                            <div class="text-center packageTxt"> Avg. TAT</div>
                            <div class="text-center packageTxt"> CUC Automation</div>
                            <div class="text-center packageTxt">{{tatAverage.AvgJsonReceived}}</div>
                        </mat-card>
                    </div>
                    <div class="col-2" style="width: 19.666667% !important">
                        <mat-card class="justify-content-center">
                            <div class="text-center packageTxt"> Avg. TAT</div>
                            <div class="text-center packageTxt"> Bookin Link Sent</div>
                            <div class="text-center packageTxt">{{tatAverage.AvgLinkSent}}</div>
                        </mat-card>
                    </div>
                    <div class="col-2" style="width: 19.666667% !important">
                        <mat-card class="justify-content-center">
                            <div class="text-center packageTxt"> Avg. TAT</div>
                            <div class="text-center packageTxt"> Bookin Ideal Time</div>
                            <div class="text-center packageTxt">{{tatAverage.AvgLinkSaveFirst}}</div>
                        </mat-card>
                    </div>
                    <div class="col-2" style="width: 19.666667% !important">
                        <mat-card class="justify-content-center">
                            <div class="text-center packageTxt"> Avg. TAT</div>
                            <div class="text-center packageTxt"> Bookin In-Process</div>
                            <div class="text-center packageTxt">{{tatAverage.AvgLinkSubmitted}}</div>
                        </mat-card>
                    </div>
                    <div class="col-2" style="width: 19.666667% !important">
                        <mat-card class="justify-content-center">
                            <div class="text-center packageTxt"> Avg. TAT</div>
                            <div class="text-center packageTxt"> Final Package Delivered</div>
                            <div class="text-center packageTxt">{{tatAverage.AvgDelivered}}</div>
                        </mat-card>
                    </div>

                </div>
            </div>
            <!-- <div class="container text-center mt-3">
            <h2>Tat tickets are displaying data for packages which are uploaded after 16th April 2021</h2>
        </div> -->

            <div class="mt-5 main-col">
                <div class="row mb-2">
                    <div class="col-1">

                    </div>
                    <div class="col-4 row">
                        <div class="col-4">

                        </div>
                        <div class="col-4">
                            Journals Count : {{journals.length - 1}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 row">
                        <div class="col-4">
                            <ejs-dropdownlist #ddlelement [dataSource]='vendors' id='vendors'
                                placeholder='Select a Vendors' popupHeight='200px' popupWidth='250px'
                                [allowFiltering]='true'></ejs-dropdownlist>
                        </div>
                        <div class="col-4">
                            <ejs-dropdownlist #ddlelement [dataSource]='journals' id='journals'
                                placeholder='Select a Journals' popupHeight='200px' popupWidth='250px'
                                [allowFiltering]='true'>
                            </ejs-dropdownlist>
                        </div>
                        <div class="col-4">
                            <ejs-dropdownlist #ddlelement [dataSource]='status' id='status'
                                placeholder='Select a Status' popupHeight='200px' popupWidth='250px'
                                [allowFiltering]='true'></ejs-dropdownlist>
                                
                        </div>
                       
                    </div>
                    <div class="col-5 row">
                        <div class="col-3 DateDiv">
                            <ejs-datepicker [value]='' format='yyyy-MM-dd' id='startDate'
                                placeholder='Enter start date'>
                            </ejs-datepicker>
                        </div>
                        <div class="col-3 DateDiv">
                            <ejs-datepicker [value]='' format='yyyy-MM-dd' id='endDate' placeholder='Enter end date'>
                            </ejs-datepicker>
                        </div>
                        <div class="col-3">
                            <ejs-multiselect id='multiselectelement' #checkbox (created)='onCreate($event)' [dataSource]='excelFilterData' [mode]="mode" [placeholder]='placeholder' [selectAllText]='selectAllText'  showSelectAll=true  
                            popupHeight='200px' popupWidth='250px'></ejs-multiselect>
                        </div>
                        <div class="col-3 PageSizeNo">
                            <input class="form-control" value="10" id="pageSize" placeholder="Page size"
                                aria-label="Search">
                        </div>
                        <!-- <div class="col-4 PageSizeNo">
                        <input class="form-control" value="10" id="pageSize" placeholder="Page size" aria-label="Search">
                    </div> -->
                    </div>
                    <div class="col-3 row">
                        <div class="col-3 me-2">
                            <button type="button" class="btn btn-success" id='submit'
                                (click)="applyFilters(search, 0, 'Id')" (click)="pageNo = 1">Submit</button>
                        </div>

                        <div class="col-3">
                            <button type="button" class="btn btn-secondary" id="Reset-btn" (click)="resetData()">Reset</button>
                        </div>

                        <div class="col-3" style="margin-left: -0.1rem !important" *ngIf="excel == 'wait'">
                            <a href="../loading-page" target="_blank">
                            <!-- <a> -->

                                <button type="button" class="btn btn-primary" id="Excel-btn" (click)="excelApiAll()">
                                    Excel
                                </button>
                            </a>

                            <!-- <div class="progress">
                                <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">{{progress}}%</div>
                              </div> -->


                        </div>

                        <button class="btn btn-primary col-3" style="margin-left: 0.7rem !important" type="button"
                            *ngIf="excel == 'downloading'">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>

                        <div class="row justify-content-end">
                            <div class="col-5 me-1">
                                <!-- <span *ngIf="excel == 'downloading' && excelReportData == 'true'"> 
                                     <h3>Getting Things Ready</h3>
                                    
                                </span>
                                <span *ngIf="excel == 'downloading' && excelAuthorQueries == 'true'">
                                    <h3>Collecting Data</h3>
                                </span> -->
                                <!-- <span *ngIf="excel == 'downloading' && excelJournalReport == 'true'">
                                    <div class="progress" >
                                        <div class="progress-bar bg-success" role="progressbar" 
                                        [style.width]="progress + '%'">{{progress}}%</div>
                                    </div>
                                </span> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="gridSection">

            <div class="mt-5">
                <div class="text-center" [ngClass]="(isLoaded==false) ? 'showField' : 'hideField'">

                    <img src="assets/images/loading.gif" class="rounded mx-auto d-block mt-2 mb-3">

                </div>
                <div [ngClass]="(isLoaded==true) ? 'showField' : 'hideField'">
                    <div class="row col-12 justify-content-center" style="height:2.5rem">
                        <div class="col row d-flex justify-content-between ms-3">
                            <div class="col-3 mt-2">
                                <h3>Total Pages: {{pageCount}}</h3>
                            </div>
                            <div class="col-4 row justify-content-end">
                                <button type="button" id="PrevFirstPage" class="btn btn-primary col-6 me-1" (click)="onFirstPage()"
                                    [ngClass]="((pageNo) == 1) ? 'disabled' : ''" style="height:2.5rem">
                                    << Prev </button>
                                        <button type="button" id="PrevLastPage" class="btn btn-primary col-5" (click)="onPrev()"
                                            [ngClass]="((pageNo) == 1) ? 'disabled' : ''" style="height:2.5rem">Prev
                                        </button>

                            </div>
                        </div>
                        <div class="col-1 text-center" style="font-size: 1rem !important;">
                            <!-- <h3>Page No: <span id="pageNo">{{pageNo}}</span></h3> -->
                            <input class="form-control text-center" value="1" id="pageNo" placeholder="Page No"
                                aria-label="Search">
                        </div>
                        <div class="col row me-3 justify-content-between">
                            <div class="col-4 row">
                                <button type="button" id="NextSecondPage" class="btn btn-primary col-5" (click)="onNext()"
                                    [ngClass]="((dataLength) == 0) || (pageNo >= pageCount) ? 'disabled' : ''"
                                    style="height:2.5rem">Next</button>

                                <button type="button" id="NextLastPage" class="btn btn-primary col-6 ms-1" (click)="onLastPage()"
                                    [ngClass]="((dataLength) == 0) || (pageNo >= pageCount) ? 'disabled' : ''"
                                    style="height:2.5rem">Next >></button>
                            </div>
                            <div class="col-3 mt-2">
                                <h3>Total Count: {{dataCount}}</h3>
                            </div>
                        </div>

                    </div>
                    
                    <!-- allowSorting='true' scrollSettings='{ width: 1200}' [sortSettings]='sortOptions' -->
                    <div class="gridData" style="overflow-x: auto;">
<!-- Button trigger modal -->
                      <!-- Modal -->
                      <!-- <div class="modal fade" id="LinkDiscard" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              ...
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                              <button type="button" class="btn btn-primary">Understood</button>
                            </div>
                          </div>
                        </div>
                      </div> -->
                        <ejs-grid [dataSource]='data' [pageSettings]='pageSettings' allowTextWrap='true' class="mt-3"
                            width=2400 height=300>
                            <e-columns>
                                <e-column field='id' headerText='ID' textAlign='center' width='40'>
                                    <!-- <ng-template #template let-data1>
                                        <a (click)="downloadOutputPackage(data1.JobId)">
                                            {{data1.id}}
                                        </a>
                                    </ng-template> -->
                                </e-column>
                                <e-column field='vendor' headerText='Vendor' width='40'></e-column>
                                <e-column field='ArticleId' headerText='Article ID' textAlign='center' format='C2'
                                    width='40'>
                                </e-column>
                                <e-column field='JournalCode' headerText='Journal Code' textAlign='center' format='yMd'
                                    width='50'>
                                </e-column>
                                <e-column field='EditorialReferenceCode' headerText='ERC' textAlign='center'
                                    format='yMd' width='60'></e-column>

                                <e-column field='InputFile' headerText='Input File' textAlign='center' format='yMd'
                                    width='50'>
                                </e-column>
                                <e-column field='ZipFile' headerText='Zip File' textAlign='center' format='yMd'
                                    width='50'>
                                </e-column>
                                <e-column field='PackageType' headerText='Book-In Type' textAlign='center' format='yMd'
                                    width='50'>
                                </e-column>
                                <e-column field='RequestId' headerText='Request ID' textAlign='center' format='yMd'
                                    width='50'>
                                </e-column>
                                <e-column field='JobId' headerText='Job ID' textAlign='center' format='yMd' width='50'>
                                </e-column>
                                <e-column field='Status' headerText='Status' textAlign='center' format='yMd' width='50'>
                                </e-column>
                                <e-column field='CreatedOn' headerText='Created On' textAlign='center' format='yMd'
                                    width='50'>
                                </e-column>

                                <e-column field='ModifiedOn' headerText='Modified On' textAlign='center' format='yMd'
                                    width='50'>
                                </e-column>
                                <e-column field='Age' headerText='Age' textAlign='center' format='yMd' width='40'>
                                </e-column>
                                <e-column field='ManuScriptError' headerText='Manuscript Error' textAlign='center'
                                    format='yMd' width='60'>
                                </e-column>
                                <e-column field='Link' headerText='Link' textAlign='center' format='yMd' width='40'>
                                    <ng-template #template let-data1>
                                        <a href={{data1?.Link}} title={{data1.Details}} target="_blank"
                                            [ngClass]="(data1?.Link == '') ? 'Invalid' : 'Valid'"
                                            style="color:blue"><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                height="16" fill="currentColor" class="bi bi-link" viewBox="0 0 16 16">
                                                <path
                                                    d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                                                <path
                                                    d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                                            </svg></a>
                                    </ng-template>
                                </e-column>
                                <!-- <e-column field='Age' headerText='Age' textAlign='center' width='0'></e-column> -->
                                <!-- <e-column field='Link' headerText='Link' textAlign='center' format='yMd' width='0'> -->
                                <!-- <e-column field='Link' headerText='Link' textAlign='center' format='yMd' width='0'>
                                <ng-template #template let-data1> 
                                    <a href={{data1?.Link}} title={{data1.Details}} target="_blank" [ngClass]="(data1?.Link == '') ? 'Invalid' : 'Valid'" style="color:blue"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link" viewBox="0 0 16 16">
                                        <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
                                        <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"/>
                                      </svg></a>
                                   </ng-template> 
                            </e-column> -->
                                <!-- Link -->
                                <!-- </e-column> -->
                                <e-column field='id' headerText='Details' textAlign='center' format='yMd' width='50'>
                                    <ng-template #template let-data1>
                                        <a title={{data1.id}} id="{{data1.id}}" (click)="openDialog(data1.id)"
                                            style="cursor:pointer;">
                                            <img src="assets/images/details_image_yellow.png"
                                                class="rounded mt-2 mb-3 me-3" style="width: 1.5rem">
                                        </a>
                                        <!-- <a rel='nofollow' href={{data.Link}}>Link</a>  -->
                                    </ng-template>
                                </e-column>
                            </e-columns>
                        </ejs-grid>

                    </div>
                </div>
            </div>
        </div>
        <!-- <h1>paging</h1> -->
    </div>
</div>
