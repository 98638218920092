<main>
<div class="container">

    <div class="row my-5">
        <div class="col-4 mx-auto">
            <div tabindex="0" class="e-card" id="basic">
                <img src="assets/images/company_logo_transparent.png" class="rounded mx-auto d-block mb-3 Logo">
                <div class="e-card-header text-center">
                    <div class="e-card-header-caption">
                        <div class="e-card-title">
                            <h4 class="heading">Create Account</h4>
                        </div>
                    </div>
                </div>
                <div class="e-card-content">
                    <div class="wrap">
                        <div class="e-float-input e-input-group e-success" id="FirstName">
                            <input type="text" id="txtFirstName" required />
                            <span class="e-float-line"></span>
                            <label class="e-float-text">First Name</label>
                        </div>
                        <div class="e-float-input e-input-group e-success" id="LastName">
                            <input type="text" required />
                            <span class="e-float-line"></span>
                            <label class="e-float-text">Last Name</label>
                        </div>
                        <div class="e-float-input e-input-group e-success" id="EmailId">
                            <input type="email" required />
                            <span class="e-float-line"></span>
                            <label class="e-float-text">Email ID</label>
                        </div>
                        <div class="e-float-input e-input-group e-success" id="Password">
                            <input type="password" required />
                            <span class="e-float-line"></span>
                            <label class="e-float-text">Password</label>
                        </div>
                        <div class="e-float-input e-input-group e-success" id="ConfirmPassword">
                            <input type="password" required />
                            <span class="e-float-line"></span>
                            <label class="e-float-text">Confirm Password</label>
                        </div>
                        <div class="signup-captcha mt-3">
                            <div class="e-float-input e-input-group captchaCol e-success">
                                <input type="text" id="txtCompare2" required />
                                <span class="e-float-line"></span>
                                <label class="e-float-text">Enter Captcha</label>
                            </div>
                            <div class="card mt-2 captchaCol" style="height: 2.5em;">
                                <input type="" class="blur noselect" id="txtCaptcha2" onmousedown="return false;"
                                    onselectstart='return false;' />
                            </div>
                        </div>

                    </div>

                </div>
                <div class="row mx-auto mb-3">
                    <button type="button" class="btn btn-success" onclick="ValidCaptcha2();">Create</button>
                </div>

                <div class="link mx-auto text-center">
                    Already have an account? <a [routerLink]="['/log-in']" href="#">Log In</a>
                </div>
                <!-- <div class="row my-3 text-center">
                    <h4 class="heading">Or</h4>
                    <h5 class="logo-heading mb-3"> Sign Up with :</h5>
                    <div class="col-4 d-flex justify-content-center"> <img src="assets/images/facebook.png" class="img-logo"></div>
                    <div class="col-4 d-flex justify-content-center"> <img src="assets/images/google-logo.png" class="img-logo"></div>
                    <div class="col-4 d-flex justify-content-center"> <img src="assets/images/linkedin.png" class="img-logo"></div>
                </div> -->

                <div class="row my-3 text-center">
                    <h4 class="heading">Or</h4>
                    <h5 class="logo-heading mb-3"> Sign Up with :</h5>
                    <div class="card">
                        <div class="card-body facebook">
                            <img src="assets/images/facebook.png" class="img-logo mx-3">
                            Sign Up with <a href='#'>Facebook.</a>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body google">
                            <img src="assets/images/google-logo.png" class="img-logo mx-3">
                            Sign Up with <a href='#'>Google.</a>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body linkedin">
                            <img src="assets/images/linkedin.png" class="img-logo mx-3">
                            Sign Up with <a href='#'>Linkedin.</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <h4 class="heading">Or</h4> -->
</div>
</main>
<footer id="footerContent2">
    <img src="assets/images/company_logo_transparent.png" class="rounded mx-auto d-block mb-3 toolbarLogo">
    <p>Powered by PubMate. IN. An iCodex Product.<br> All rights reserved by iCodex 2021</p>
</footer>