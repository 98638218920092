<main>
<div class="container text-center">
    <!-- <img src="assets/images/company_logo_transparent.png" class="rounded mx-auto d-block mb-3 Logo"> -->
    <div class='heading my-5'>404 <br> Page Not Found </div>
    <br>
    <div class='msg'>We are very sorry, but the URL you typed no longer exists. <br> It might have been renamed or removed, or you may have mistyped it.</div>
    <div class="link mx-auto mt-5">
        <a [routerLink]="['/log-in']" href="#">Back to Log in</a>
    </div>
</div>
</main>
<footer id="footerContent5">
    <!-- <img src="assets/images/company_logo_transparent.png" class="rounded mx-auto d-block mb-3 toolbarLogo"> -->
    <p>Powered by PubMate. IN. An iCodex Product.<br> All rights reserved by iCodex 2021</p>
</footer>